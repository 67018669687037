import React from 'react';
import PropTypes from 'prop-types';
import Header from 'common/compound-components/Header/components/HeaderComponent';
import LikedHotels from 'common/compound-components/LikedHotels/containers/LikedHotelsContainer';
import RecentlyViewedHotels from 'common/compound-components/RecentlyViewedHotels/containers/RecentlyViewedHotelsContainer';
import Countries from 'common/compound-components/Сountries';
import CompilationsHotels from 'common/compound-components/CompilationsHotels';
import SwipeableSearchParamsPanel from 'common/base-components/SwipeableSearchParamsPanel';
import CompilationHotelsByName from 'common/compound-components/CompilationHotelsByName';

import styles from '../assets/Home.module.scss';

const HomeComponent = ({ history, likedHotelsIds }) => (
  <div className="content-wrapper">
    <Header />
    <section className={styles.itemWrapper}>
      <CompilationHotelsByName compilationName="Популярные отели" />
    </section>
    <section className={styles.itemWrapper}>
      <Countries />
    </section>
    <section className={styles.compilationsBlocksWrapper}>
      <hr className={styles.horizontalLine} />
      <div className={styles.compilationsBlocks}>
        <LikedHotels />
        <RecentlyViewedHotels />
        <CompilationsHotels />
      </div>
      <hr className={styles.horizontalLine} />
    </section>
    <SwipeableSearchParamsPanel history={history} initialValues={{ likedHotelsIds }} />
  </div>
);

HomeComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  likedHotelsIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default HomeComponent;
