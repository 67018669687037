import React from 'react';
import PropTypes from 'prop-types';
import HotelCard from './HotelCard';

import styles from './assets/HotelsList.module.scss';

const HotelsList = ({ items, selectedDelightersKeys }) => (
  <div>
    {items.map((hotel) => (
      <HotelCard
        key={hotel.id}
        item={hotel}
        selectedDelightersKeys={selectedDelightersKeys}
        className={styles.hotelCard}
      />
    ))}
  </div>
);

HotelsList.defaultProps = {
  selectedDelightersKeys: undefined
};

HotelsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDelightersKeys: PropTypes.arrayOf(PropTypes.string)
};

export default HotelsList;
