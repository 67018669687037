import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home/containers/HomeContainer';
import SearchResults from './SearchResults/containers/SearchResultsContainer';
import Details from './Details/containers/DetailsContainer';
import UploadForm from './UploadForm';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/search-results" component={SearchResults} />
    <Route path="/details/:hotelId" component={Details} />
    <Route path="/upload-form" component={UploadForm} />
    <Route path="/terms-of-use" component={TermsOfUse} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
  </Switch>
);

export default AppRoutes;
