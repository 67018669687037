import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import cn from 'classnames';

import styles from '../assets/LikeButton.module.scss';

const LikeButton = ({ likeHotel, unlikeHotel, hotelId, likedHotelsIds, className }) => {
  const isActive = React.useMemo(() => likedHotelsIds.includes(hotelId), [likedHotelsIds.length]);

  const handleButtonClick = (event) => {
    const checkedMethod = isActive ? unlikeHotel : likeHotel;

    event.stopPropagation();
    event.preventDefault();
    checkedMethod(hotelId);
  };

  return (
    <IconButton className={cn(styles.button, className)} onClick={handleButtonClick}>
      <div className={styles.substrate}>{isActive ? <FavoriteIcon /> : <FavoriteBorderIcon />}</div>
    </IconButton>
  );
};

LikeButton.defaultProps = {
  className: ''
};

LikeButton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  likeHotel: PropTypes.func.isRequired,
  unlikeHotel: PropTypes.func.isRequired,
  hotelId: PropTypes.number.isRequired,
  likedHotelsIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default LikeButton;
