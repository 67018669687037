import reducerRegistry from 'reducerRegistry';
import {
  DELIGHTERS_GET_DELIGHTERS_BEGIN,
  DELIGHTERS_GET_DELIGHTERS_SUCCESS,
  DELIGHTERS_GET_DELIGHTERS_ERROR
} from './actionTypes';

const initialState = {
  items: [],
  loading: false,
  error: ''
};

const delightersReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIGHTERS_GET_DELIGHTERS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case DELIGHTERS_GET_DELIGHTERS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false
      };
    case DELIGHTERS_GET_DELIGHTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

reducerRegistry.register('delighters', delightersReducer);
