import {
  SEARCH_RESULTS_GET_HOTELS_BEGIN,
  SEARCH_RESULTS_GET_HOTELS_SUCCESS,
  SEARCH_RESULTS_GET_HOTELS_ERROR,
  SEARCH_RESULTS_RESET_STATE
} from './actionTypes';

export const getHotelsBegin = () => ({
  type: SEARCH_RESULTS_GET_HOTELS_BEGIN
});

export const getHotelsSuccess = (payload) => ({
  type: SEARCH_RESULTS_GET_HOTELS_SUCCESS,
  payload
});

export const getHotelsError = (payload) => ({
  type: SEARCH_RESULTS_GET_HOTELS_ERROR,
  payload
});

export const resetState = () => ({
  type: SEARCH_RESULTS_RESET_STATE
});
