import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import groupBy from 'helpers/groupBy';
import Title from 'common/base-components/Title';
import FactsList from './FactsList';

import styles from './assets/FiltersByFactsBlocks.module.scss';

const FiltersByFactsBlocks = ({ componentRef, initialValues }) => {
  // convert initialValues array to object ({key: true}) for initial selectedItems
  const transformedInitialValues = React.useMemo(
    () => initialValues.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    []
  );
  const [facts, setFacts] = React.useState({});
  const [selectedItems, setSelectedItems] = React.useState(transformedInitialValues);

  const getFacts = async () => {
    try {
      const { data } = await axios.get('/facts');

      const groupedData = groupBy(data, 'ruGroup', undefined, (key) => (!key ? 'Другое' : key));
      const popularFilter = {
        'Популярные фильтры': data.filter((item) => item.isPopular)
      };

      setFacts({ ...popularFilter, ...groupedData });
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getFacts();
  }, []);

  const getNewSelectedItems = (name, checked) => {
    const newSelectedItems = { ...selectedItems };

    if (checked) {
      newSelectedItems[name] = checked;
    } else {
      delete newSelectedItems[name];
    }

    return newSelectedItems;
  };

  const handleChange = (event) => {
    const {
      currentTarget: { checked, name }
    } = event;

    setSelectedItems(getNewSelectedItems(name, checked));
  };

  const handleReset = () => {
    setSelectedItems(transformedInitialValues);
  };

  React.useImperativeHandle(componentRef, () => ({
    selectedItems,
    handleReset
  }));

  return (
    <div>
      {Object.keys(facts).map((factKey) => (
        <div key={factKey} className={styles.factWrapper}>
          <Title className={styles.title}>{factKey}</Title>
          <FactsList items={facts[factKey]} onChange={handleChange} selectedItems={selectedItems} />
        </div>
      ))}
    </div>
  );
};
FiltersByFactsBlocks.defaultProps = {
  initialValues: [],
  componentRef: null
};

FiltersByFactsBlocks.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.string),
  componentRef: PropTypes.any
};

export default React.forwardRef((props, ref) => (
  <FiltersByFactsBlocks componentRef={ref} {...props} />
));
