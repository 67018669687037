import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import customWithTranslation from 'helpers/language/customWithTranslation';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import SvgInline from 'common/base-components/SvgInline';
import getImageSrc from 'helpers/getImageSrc';

import styles from './assets/SelectedDelighters.module.scss';

const SelectedDelighters = ({
  selectedDelightersKeys,
  delighters,
  subDelighters,
  positiveDelights,
  hotelName,
  onChange,
  tBack
}) => {
  const selectedDelighters = React.useMemo(
    () => delighters.filter((delighter) => selectedDelightersKeys.includes(delighter.key)),
    [delighters, selectedDelightersKeys]
  );
  const [activeDelighter, setActiveDelighter] = React.useState(selectedDelighters[0]);
  const activeSubDelighters = React.useMemo(
    () => subDelighters.filter((item) => item.delight.key === activeDelighter.key),
    [subDelighters, activeDelighter]
  );

  const currentPositiveDelight = React.useMemo(
    () => positiveDelights.filter((el) => el.key === activeDelighter.key)[0] ?? {},
    [activeDelighter, positiveDelights]
  );

  return (
    <>
      <VerticalScrollableBlock className={styles.verticalScrollableBlock}>
        <>
          {selectedDelighters.map((delighter) => (
            <div
              key={delighter.id}
              role="button"
              tabIndex={0}
              className={cn(
                styles.delighterItem,
                activeDelighter.id === delighter.id && styles.selectedItem
              )}
              onClick={() => setActiveDelighter(delighter)}
              onKeyDown={() => setActiveDelighter(delighter)}
            >
              <div className={styles.delighterIconWrapper}>
                <div className={styles.delighterIcon}>
                  <SvgInline url={getImageSrc(delighter.image)} />
                </div>
              </div>
              <div className={styles.title}>{tBack(delighter, 'name')}</div>
            </div>
          ))}
        </>
      </VerticalScrollableBlock>
      <div className={styles.activeContent}>
        <h4>Что особенно понравилось?</h4>
        <h5>{hotelName}</h5>

        <ul>
          {activeSubDelighters.map((subDelight) => (
            <li
              key={subDelight.id}
              className={cn(
                (currentPositiveDelight.subDelightKeys ?? []).includes(subDelight.key) &&
                  styles.activeSubDelight
              )}
              role="presentation"
              onClick={() => {
                onChange({ key: activeDelighter.key, subDelightKey: subDelight.key });
              }}
            >
              {tBack(subDelight, 'name')}
            </li>
          ))}
        </ul>
        <textarea
          name="comment"
          id="comment"
          value={currentPositiveDelight.comment ?? ''}
          onChange={(event) => {
            onChange({ key: activeDelighter.key, comment: event.target.value });
          }}
          placeholder="Ваш комментарий..."
        />
      </div>
    </>
  );
};

SelectedDelighters.propTypes = {
  selectedDelightersKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  delighters: PropTypes.array.isRequired,
  subDelighters: PropTypes.array.isRequired,
  hotelName: PropTypes.string.isRequired,
  positiveDelights: PropTypes.array.isRequired,
  tBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default customWithTranslation()(SelectedDelighters);
