import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import RoomIcon from '@material-ui/icons/Room';
import CircularProgress from '@material-ui/core/CircularProgress';
import HotelCard from 'common/base-components/Hotels/HotelCard';

import styles from './assets/GoogleMap.module.scss';

const MINIMUM_CLUSTER_SIZE = 2;

const InfoModal = ({ item, isLoading }) => {
  const renderCard = React.useCallback(() => {
    if (!item && isLoading) {
      return (
        <div className={styles.infoModalWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (!item && !isLoading) {
      return (
        <div className={styles.infoModalWrapper}>
          <p>Not found</p>
        </div>
      );
    }

    return <HotelCard item={item} isInline />;
  }, [item, isLoading]);

  return <div className={styles.infoModal}>{renderCard()}</div>;
};

InfoModal.defaultProps = {
  item: undefined
};

InfoModal.propTypes = {
  item: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
};

const Marker = ({ item, onClick, isActive }) => {
  const handleIconClick = (event) => {
    onClick(item);

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <RoomIcon
      className={cn(styles.mapMarker, isActive && styles.activeMapMarker)}
      onClick={handleIconClick}
    />
  );
};

Marker.defaultProps = {
  item: {}
};

Marker.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

const GoogleMap = ({ items, withInfoModal }) => {
  const { _env_: env } = window;
  const [selectedItem, setSelectedItem] = React.useState({});
  const [loadedItems, setLoadedItems] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [mapMarkers, setMapMarkers] = React.useState([]);
  const cluster = React.useRef(null);

  const handleLoadItem = React.useCallback(async (hotelId) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(`/hotels/${hotelId}`);

      setLoadedItems({ ...loadedItems, [hotelId]: data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      throw new Error(error);
    }
  }, []);

  const handleMarkerClick = React.useCallback((item) => {
    setSelectedItem(item);

    if (!setLoadedItems[item.id]) {
      handleLoadItem(item.id);
    }
  }, []);

  const getMapBounds = React.useCallback((map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.locationLat, place.locationLon));
    });

    return bounds;
  }, []);

  const getMarkersClustered = React.useCallback(() => {
    const coordinates = [];
    const markersClustered = cluster.current.getClusters().map((cl) => cl.getMarkers());
    const markersGrouped = markersClustered.filter(
      (marker) => marker.length >= MINIMUM_CLUSTER_SIZE
    );

    markersGrouped.forEach((markers) => {
      markers.forEach((marker) => {
        coordinates.push({
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng()
        });
      });
    });

    return coordinates;
  }, [cluster.current]);

  const hideMarkersClustered = React.useCallback(
    (dataItems) => {
      if (!cluster.current) {
        return;
      }

      const coordinates = getMarkersClustered();

      const places = dataItems.filter(
        (dataItem) =>
          !coordinates.some(
            (coordinate) =>
              coordinate.lat === dataItem.locationLat && coordinate.lng === dataItem.locationLon
          )
      );

      setMapMarkers(places);
    },
    [cluster.current]
  );

  const onApiLoaded = React.useCallback((map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);

    const markers = items.map((item) => {
      const marker = new maps.Marker({
        position: { lat: item.locationLat, lng: item.locationLon }
      });

      return marker;
    });

    // Fit map to bounds. Auto-center and auto-zoom
    map.fitBounds(bounds);
    map.panToBounds(bounds);

    cluster.current = new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 20,
      maxZoom: 16,
      minimumClusterSize: MINIMUM_CLUSTER_SIZE
    });

    maps.event.addListener(cluster.current, 'clusteringend', () => {
      hideMarkersClustered(items);

      // remove google markers
      if (markers) {
        markers.forEach((el) => {
          el.setMap(null);
        });
      }
    });
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: env.GOOGLE_MAPS_KEY }}
        defaultCenter={{ lat: items[0].locationLat, lng: items[0].locationLon }}
        defaultZoom={!withInfoModal ? 9 : 3}
        onClick={() => setSelectedItem({})}
        onChange={() => {
          hideMarkersClustered(items);
        }}
        center={
          Object.keys(selectedItem).length > 0
            ? { lat: selectedItem.locationLat, lng: selectedItem.locationLon }
            : undefined
        }
        options={{
          gestureHandling: 'greedy',
          maxZoom: 16
        }}
        draggable={!withInfoModal || Object.keys(selectedItem).length === 0}
        onGoogleApiLoaded={({ map, maps }) => onApiLoaded(map, maps, items)}
        yesIWantToUseGoogleMapApiInternals
      >
        {mapMarkers.map((item) => (
          <Marker
            key={item.id}
            lat={item.locationLat}
            lng={item.locationLon}
            item={item}
            isActive={selectedItem.id === item.id}
            onClick={handleMarkerClick}
          />
        ))}
      </GoogleMapReact>
      {withInfoModal && Object.keys(selectedItem).length > 0 && (
        <InfoModal
          lat={selectedItem.locationLat}
          lng={selectedItem.locationLon}
          isLoading={isLoading}
          item={loadedItems[selectedItem.id]}
        />
      )}
    </div>
  );
};

GoogleMap.defaultProps = {
  withInfoModal: false
};

GoogleMap.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      locationLat: PropTypes.number,
      locationLon: PropTypes.number
    })
  ).isRequired,
  withInfoModal: PropTypes.bool
};

export default GoogleMap;
