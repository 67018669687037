import { connect } from 'react-redux';
import axios from 'axios';
import DelightersComponent from '../components/DelightersComponent';
import { getDelightersBegin, getDelightersSuccess, getDelightersError } from '../actions';
import '../index';

const getDelighters = () => async (dispatch) => {
  try {
    dispatch(getDelightersBegin());

    const { data } = await axios.get('/delights');

    dispatch(getDelightersSuccess({ items: data }));
  } catch (error) {
    dispatch(getDelightersError(error));

    throw new Error(error);
  }
};

const mapDispatchToProps = (dispatch) => ({
  getDelighters: () => dispatch(getDelighters())
});

const mapStateToProps = (state) => ({
  items: state.delighters.items
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  DelightersComponent
);
