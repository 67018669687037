import {
  DETAILS_GET_HOTEL_BEGIN,
  DETAILS_GET_HOTEL_SUCCESS,
  DETAILS_GET_HOTEL_ERROR,
  DETAILS_RESET_STATE
} from './actionTypes';

export const getHotelBegin = () => ({
  type: DETAILS_GET_HOTEL_BEGIN
});

export const getHotelSuccess = (payload) => ({
  type: DETAILS_GET_HOTEL_SUCCESS,
  payload
});

export const getHotelError = (payload) => ({
  type: DETAILS_GET_HOTEL_ERROR,
  payload
});

export const resetState = () => ({
  type: DETAILS_RESET_STATE
});
