import {
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_BEGIN,
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_SUCCESS,
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_ERROR
} from './actionTypes';

export const getRecentlyViewedHotelsBegin = () => ({
  type: RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_BEGIN
});

export const getRecentlyViewedHotelsSuccess = (payload) => ({
  type: RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_SUCCESS,
  payload
});

export const getRecentlyViewedHotelsError = (payload) => ({
  type: RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_ERROR,
  payload
});
