import React from 'react';
import PropTypes from 'prop-types';
import LoadingProgress from 'common/base-components/LoadingProgress';
import LoadMoreButton from 'common/base-components/LoadMoreButton';
import FeedbackCard from './FeedbackCardComponent';

const checkData = (data, selectedItem, emotion, isInitialGet) => {
  if (!isInitialGet) {
    return data;
  }

  if (selectedItem.emotion?.toLowerCase() !== emotion.toLowerCase()) {
    return data;
  }

  const newData = [...data];
  const selectedItemIndex = newData.findIndex((el) => el.id === selectedItem.id);
  newData.splice(selectedItemIndex, 1); // remove from newData
  newData.unshift(selectedItem); // add to first place in newData

  return newData;
};

const FeedbacksListComponent = ({
  getFeedbacks,
  hotelId,
  emotion,
  cardClassName,
  onTotalCountChange,
  onCardClick,
  selectedItem,
  shouldAddTitleCaption,
  isFull
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [feedbacks, setFeedbacks] = React.useState([]);
  const [feedbacksTotalCount, setFeedbacksTotalCount] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(0);
  const [isFeedbacksLoading, setIsFeedbacksLoading] = React.useState(false);
  const isInitialGet = React.useRef(true);

  const onGetFeedbacks = async () => {
    try {
      setIsFeedbacksLoading(true);

      const { data, headers } = await getFeedbacks(hotelId, emotion, currentPage);
      const { total_elements: totalElements, total_pages: resonseTotalPages } = headers;
      const checkedData =
        Object.keys(selectedItem).length > 0
          ? checkData(data, selectedItem, emotion, isInitialGet)
          : data;

      setFeedbacksTotalCount(totalElements);
      setFeedbacks([...feedbacks, ...checkedData]);
      setIsFeedbacksLoading(false);

      if (totalPages !== resonseTotalPages) {
        setTotalPages(Number(resonseTotalPages));
      }

      isInitialGet.current = false;
    } catch (error) {
      setIsFeedbacksLoading(false);

      throw new Error(error);
    }
  };

  React.useEffect(() => {
    onGetFeedbacks();
  }, [currentPage]);

  React.useEffect(() => {
    onTotalCountChange(feedbacksTotalCount);
  }, [feedbacksTotalCount]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  if (!isFeedbacksLoading && feedbacks.length === 0) {
    return <h5>Пусто</h5>;
  }

  return (
    <>
      {feedbacks.map((feedback) => (
        <FeedbackCard
          key={feedback.id}
          item={feedback}
          className={cardClassName}
          onCardClick={onCardClick}
          shouldAddTitleCaption={shouldAddTitleCaption}
          isFull={isFull}
        />
      ))}
      {isFeedbacksLoading && <LoadingProgress />}
      {isFull && feedbacks.length > 0 && (
        <LoadMoreButton
          onClick={handleLoadMore}
          disabled={isFeedbacksLoading || totalPages === 1 || totalPages === currentPage}
        />
      )}
    </>
  );
};

FeedbacksListComponent.defaultProps = {
  emotion: undefined,
  cardClassName: '',
  onTotalCountChange: () => {},
  onCardClick: () => {},
  isFull: false,
  selectedItem: {},
  shouldAddTitleCaption: false
};

FeedbacksListComponent.propTypes = {
  emotion: PropTypes.string,
  hotelId: PropTypes.number.isRequired,
  getFeedbacks: PropTypes.func.isRequired,
  cardClassName: PropTypes.string,
  onTotalCountChange: PropTypes.func,
  onCardClick: PropTypes.func,
  isFull: PropTypes.bool,
  selectedItem: PropTypes.object,
  shouldAddTitleCaption: PropTypes.bool
};

export default FeedbacksListComponent;
