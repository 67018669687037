import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cn from 'classnames';

import styles from './assets/SvgInline.module.scss';

const SvgInline = ({ url, className }) => {
  const [svg, setSvg] = React.useState(null);

  const getSvgIcon = async () => {
    try {
      const { data } = await axios.get(url);

      setSvg(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    if (!url) {
      return;
    }

    getSvgIcon();
  }, []);

  return (
    <span dangerouslySetInnerHTML={{ __html: svg }} className={cn(styles.wrapper, className)} /> // eslint-disable-line
  );
};

SvgInline.defaultProps = {
  className: ''
};

SvgInline.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SvgInline;
