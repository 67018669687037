import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import LoadingProgress from 'common/base-components/LoadingProgress';
import customWithTranslation from 'helpers/language/customWithTranslation';
import Title from 'common/base-components/Title';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';
import SvgInline from 'common/base-components/SvgInline';
import getImageSrc from 'helpers/getImageSrc';
import groupBy from 'helpers/groupBy';

import styles from '../assets/Facilities.module.scss';

const FacilitiesList = ({ tBack, facilities }) => (
  <div className={styles.list}>
    {Object.keys(facilities).map((facilityKey) => (
      <Accordion
        key={facilityKey}
        classes={{ root: styles.accordion, expanded: styles.expandedAccordion }}
        defaultExpanded={facilities[facilityKey].isSelected}
      >
        <AccordionSummary
          expandIcon={<ArrowRightIcon />}
          classes={{
            root: styles.accordionSummary,
            content: styles.accordionSummaryContent,
            expandIcon: styles.expandedIconAccordionSummary
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <SvgInline
            url={getImageSrc(facilities[facilityKey].items[0].image)}
            className={styles.summaryIcon}
          />
          <Typography className={styles.accordionTitle}>{facilityKey}</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <ul className={styles.facilitiesList}>
            {facilities[facilityKey].items.map((facilityItem) => (
              <li key={facilityItem.key}>
                <CheckIcon className={styles.facilityCheckIcon} /> {tBack(facilityItem, 'name')}
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
);

FacilitiesList.propTypes = {
  facilities: PropTypes.object.isRequired,
  tBack: PropTypes.func.isRequired
};

const FacilitiesComponent = ({ hotelId, tBack, selectedItems }) => {
  const [facilities, setFacilities] = React.useState({});
  const [isFacilitiesLoading, setIsFacilitiesLoading] = React.useState(false);

  React.useEffect(() => {
    const transformGroupedDataBySelectedItems = (groupedData) =>
      Object.keys(groupedData).reduce((acc, curr) => {
        acc[curr] = {
          items: groupedData[curr],
          isSelected: groupedData[curr].some((el) => selectedItems.includes(el.key))
        };

        return acc;
      }, {});

    const getFacilities = async () => {
      try {
        setIsFacilitiesLoading(true);

        const { data } = await axios.get(`/hotels/${hotelId}/facts`);
        const groupedData = groupBy(data, 'ruGroup', undefined);

        setFacilities(transformGroupedDataBySelectedItems(groupedData));
        setIsFacilitiesLoading(false);
      } catch (error) {
        setIsFacilitiesLoading(false);

        throw new Error(false);
      }
    };

    getFacilities();
  }, [hotelId]);

  return (
    <div>
      <SpaceBetweenBlock>
        <Title>Удобства</Title>
      </SpaceBetweenBlock>
      {isFacilitiesLoading && <LoadingProgress />}
      <FacilitiesList tBack={tBack} facilities={facilities} selectedItems={selectedItems} />
    </div>
  );
};

FacilitiesComponent.defaultProps = {
  selectedItems: []
};

FacilitiesComponent.propTypes = {
  hotelId: PropTypes.number.isRequired,
  tBack: PropTypes.func.isRequired,
  selectedItems: PropTypes.array
};

export default customWithTranslation()(FacilitiesComponent);
