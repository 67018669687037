import React from 'react';
import axios from 'axios';
import ProfileWrapperContext from '../components/ProfileWrapperContext';

const useUserProfile = () => {
  const { profile, setProfile } = React.useContext(ProfileWrapperContext);
  const profileWasChecked = React.useMemo(() => localStorage.getItem('profileWasChecked'));

  React.useEffect(() => {
    const getUserProfile = async () => {
      try {
        const { data } = await axios.get('/users/profile');

        setProfile(data);
        localStorage.setItem('profile', JSON.stringify(data));
      } catch (error) {
        throw new Error(error);
      }

      // FOR LOCAL DEVELOPMENT
      // const data = {
      //   active: true,
      //   currentSessionId: null,
      //   email: 'mymail@gmail.com',
      //   id: 2112,
      //   invitedCode: null,
      //   name: 'Johnny Depp',
      //   picture:
      //     'https://lh3.googleusercontent.com/a/AATXAJyONJnCTVfRh0i4B4W9FOuvP_fJ3yJnDdbjP4X4=s96-c',
      //   provider: null
      // };
      // setProfile(data);
      // localStorage.setItem('profile', JSON.stringify(data));
    };

    if (!profileWasChecked && Object.keys(profile).length === 0) {
      getUserProfile();

      localStorage.setItem('profileWasChecked', true);
    }
  }, []);

  const resetProfile = React.useCallback(() => {
    setProfile({});

    localStorage.removeItem('profile');
  }, []);

  const onActivateSuccess = React.useCallback(() => {
    const newProfile = {
      ...profile,
      active: true
    };

    localStorage.setItem('profile', JSON.stringify(newProfile));

    setProfile(newProfile);
  }, [profile]);

  return { profile, resetProfile, onActivateSuccess };
};

export default useUserProfile;
