import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const initI18n = (store) => {
  const {
    settings: { language }
  } = store.getState();

  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: false,
      backend: {
        loadPath: '/translations/{{lng}}/{{ns}}.json',
        addPath: '/translations/add/{{lng}}/{{ns}}'
      },
      lng: language ?? 'ru',
      defaultNS: 'Common',
      fallbackNS: 'Common',
      fallbackLng: ['ru'],
      saveMissing: true,
      saveMissingTo: 'all',

      interpolation: {
        escapeValue: false // react already safes from xss
      },
      react: {
        wait: true,
        useSuspense: false
      }
    });
};

export default initI18n;
