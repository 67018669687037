import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './assets/LoadingProgress.module.scss';

const LoadingProgress = () => (
  <div className={styles.wrapper}>
    <CircularProgress />
  </div>
);

LoadingProgress.propTypes = {};

export default LoadingProgress;
