import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import qs from 'query-string';
import axios from 'axios';
import customWithTranslation from 'helpers/language/customWithTranslation';
import ImageSlider from 'common/base-components/ImageSlider';
import HotelCardDelighters from 'common/base-components/HotelCardDelighters';
import WeatherWidget from 'common/compound-components/WeatherWidget';
import CompilationHotelsByName from 'common/compound-components/CompilationHotelsByName';
import LikedHotels from 'common/compound-components/LikedHotels/containers/LikedHotelsContainer';
import RecentlyViewedHotels from 'common/compound-components/RecentlyViewedHotels/containers/RecentlyViewedHotelsContainer';
import Feedbacks from 'common/compound-components/Feedbacks';
import Header from 'common/compound-components/Header/components/HeaderComponent';
import GoogleMap from 'common/base-components/GoogleMap';
import useUserProfile from 'common/compound-components/Header/hook/useUserProfile';
import AddFeedback from 'common/compound-components/AddFeedback';
import HeaderDetails from './HeaderDetailsComponent';
import Facilities from './FacilitiesComponent';

import styles from '../assets/Details.module.scss';

const DetailsComponent = ({
  match,
  tBack,
  getHotel,
  setAsViewedHotel,
  resetState,
  item,
  loading,
  location
}) => {
  const { profile } = useUserProfile();
  const [delighters, setDelighters] = React.useState([]);
  const parsedSearchParams = React.useMemo(
    () => qs.parse(location.search, { arrayFormat: 'bracket' }),
    [location.search]
  );
  const {
    params: { hotelId }
  } = match;

  React.useEffect(() => {
    const getDelighters = async () => {
      try {
        const { data } = await axios.get(`/hotels/${hotelId}/delights`);

        setDelighters(data);
      } catch (error) {
        throw new Error(error);
      }
    };

    getDelighters();
    getHotel(hotelId);
    setAsViewedHotel(hotelId);

    return () => {
      resetState();
    };
  }, [hotelId]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (!loading && Object.keys(item).length === 0) {
    return <p>Не найдено</p>;
  }

  return (
    <div className="content-wrapper">
      <Header />
      <HeaderDetails item={item} />
      <div className={styles.emptySpaceWrapper}>
        <ImageSlider images={item.images} hotelId={item.id} />
        <AddFeedback
          hotelId={item.id}
          hotelName={item.name}
          delighters={delighters}
          isDisabled={!profile.active}
        />
        <div className={styles.delighters}>
          <HotelCardDelighters
            delighters={delighters}
            selectedDelightersKeys={parsedSearchParams.delighters}
            isSelectable
          />
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <Feedbacks hotelId={item.id} />
      </div>
      <div className={cn(styles.emptySpaceWrapper, styles.mapWrapper)}>
        <GoogleMap items={[item]} />
      </div>
      <div className={styles.blockWrapper}>
        <Facilities hotelId={item.id} selectedItems={parsedSearchParams.facts} />
      </div>
      <div className={styles.blockWrapper}>
        <WeatherWidget country={tBack(item, 'country')} location={tBack(item, 'locationName')} />
      </div>
      <div className={styles.compilationsBlocks}>
        <LikedHotels />
        <RecentlyViewedHotels />
        <CompilationHotelsByName compilationName="Лучшие пляжи" size="small" />
      </div>
    </div>
  );
};

DetailsComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  tBack: PropTypes.func.isRequired,
  getHotel: PropTypes.func.isRequired,
  setAsViewedHotel: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired
  }).isRequired
};

export default customWithTranslation()(DetailsComponent);
