import React from 'react';
import PropTypes from 'prop-types';
import customWithTranslation from 'helpers/language/customWithTranslation';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useHistory } from 'react-router-dom';
import getImageSrc from 'helpers/getImageSrc';

import styles from './assets/CountryCard.module.scss';

const CountryCard = ({ tBack, item }) => {
  const history = useHistory();

  function handleClick() {
    history.push({
      pathname: '/search-results',
      search: `locationName=${item.ruName}`
    });
  }

  return (
    <div className={styles.wrapper}>
      <CardActionArea onClick={handleClick}>
        <div
          className={styles.imageBg}
          style={{ backgroundImage: `url(${getImageSrc(item.image)})` }}
        />
        <div className={styles.location}>
          <LocationOnOutlinedIcon className={styles.locationIcon} /> {tBack(item, 'name')}
        </div>
      </CardActionArea>
    </div>
  );
};

CountryCard.propTypes = {
  tBack: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default customWithTranslation()(CountryCard);
