import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Container from '@material-ui/core/Container';
import cn from 'classnames';

import ScrollToTop from 'common/base-components/ScrollToTop';
import DesktopWarning from 'common/base-components/DesktopWarning';
import OnBoardStepper from 'common/base-components/OnBoardStepper';
import ProfileWrapper from 'common/compound-components/Header/components/ProfileWrapper';

import AppRoutes from './AppRoutes';

import '../assets/styles/global.scss';
import styles from './App.module.scss';

const App = () => {
  const isMobileDevice = useSelector((state) => state.settings.isMobileDevice);
  const isOnBoardPageWasApplied = useSelector((state) => state.settings.isOnBoardPageWasApplied);
  const isAgreedDesktopView = React.useMemo(() => localStorage.getItem('agreedDesktopView'));

  return (
    <ProfileWrapper>
      <SnackbarProvider classes={{ variantInfo: styles.snackbarInfo }} preventDuplicate>
        {!isMobileDevice && !isAgreedDesktopView && <DesktopWarning />}
        {!isOnBoardPageWasApplied && <OnBoardStepper />}
        <BrowserRouter>
          <ScrollToTop />
          <Container
            className={cn(styles.rootContainer, !isMobileDevice && styles.desktopRootContainer)}
          >
            <AppRoutes />
          </Container>
        </BrowserRouter>
      </SnackbarProvider>
    </ProfileWrapper>
  );
};

export default App;
