import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';

import styles from './assets/SearchInput.module.scss';

const SearchInput = ({ inputClassName, searchInputProps, placeholder }) => (
  <TextField
    id="search"
    autoComplete="off"
    placeholder={placeholder}
    className={cn(styles.searchField, inputClassName)}
    variant="outlined"
    color="secondary"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start" className={styles.searchInputAdornment}>
          <SearchIcon />
        </InputAdornment>
      )
    }}
    {...searchInputProps}
  />
);

SearchInput.defaultProps = {
  inputClassName: undefined,
  placeholder: ''
};

SearchInput.propTypes = {
  inputClassName: PropTypes.string,
  searchInputProps: PropTypes.object.isRequired,
  placeholder: PropTypes.string
};

export default SearchInput;
