import React from 'react';
import axios from 'axios';
import Title from 'common/base-components/Title';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import LoadingProgress from 'common/base-components/LoadingProgress';
import CountryCard from './CountryCard';

const Сountries = () => {
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getСountries = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get('/countries');

      setItems(data);
      setIsLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getСountries();
  }, []);

  return (
    <div>
      <SpaceBetweenBlock>
        <Title>Страны</Title>
      </SpaceBetweenBlock>
      <VerticalScrollableBlock>
        {isLoading && <LoadingProgress />}
        {items.map((item) => (
          <CountryCard key={item.ruName} item={item} />
        ))}
      </VerticalScrollableBlock>
    </div>
  );
};

export default Сountries;
