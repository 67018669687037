import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GoogleIcon from './assets/icons/google.png';
// import FacebookIcon from './assets/icons/fb.png';
// import AppleIcon from './assets/icons/apple.png';

import styles from './assets/SignInDialog.module.scss';

const socialButtons = [
  {
    id: 'google',
    title: 'Google',
    icon: GoogleIcon
  }
  // {
  //   id: 'facebook',
  //   title: 'Facebook',
  //   icon: FacebookIcon
  // },
  // {
  //   id: 'apple',
  //   title: 'Apple',
  //   icon: AppleIcon
  // }
];

const SignInDialog = ({ open, onClose, onLogIn }) => (
  <Dialog
    open={open}
    onClose={onClose}
    classes={{ container: styles.dialogContainer, paper: styles.paper }}
  >
    <div className={styles.root}>
      <header className={styles.header}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <div className={styles.logoBlock}>
        <img src="/logo.png" alt="AskBee" />
      </div>
      <div className={styles.socialButtonsWrapper}>
        {socialButtons.map((socialButton) => (
          <Button
            key={socialButton.id}
            className={styles.socialButton}
            data-social-type={socialButton.id}
            onClick={() => onLogIn(socialButton.id)}
          >
            <div className={styles.iconWrapper}>
              <img src={socialButton.icon} alt={`Войти через ${socialButton.title}`} />
            </div>
            Войти через {socialButton.title}
          </Button>
        ))}
      </div>
      <div className={styles.infoBlock}>
        <p>Войдя, вы принимаете эти условия AskBee:</p>
        <p className={styles.terms}>
          <Link to="/terms-of-use">Условия использования</Link> и{' '}
          <Link to="/privacy-policy">Политика конфиденциальности.</Link>
        </p>
      </div>
    </div>
  </Dialog>
);

SignInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogIn: PropTypes.func.isRequired
};

export default SignInDialog;
