import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import arrayDiff from 'helpers/arrayDifference';
import LikedHotelsComponent from '../components/LikedHotelsComponent';
import { getLikedHotelsBegin, getLikedHotelsSuccess, getLikedHotelsError } from '../actions';
import '../index';

const getLikedHotels = (itemsIds, loadMore) => async (dispatch, getState) => {
  try {
    dispatch(getLikedHotelsBegin());

    const {
      likedHotels: { items }
    } = getState();
    const { data } = await axios.get('/hotels/list', {
      params: {
        id: itemsIds
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
    const checkedData = loadMore ? [...items, ...data] : data;

    dispatch(getLikedHotelsSuccess({ items: checkedData }));
  } catch (error) {
    dispatch(getLikedHotelsError(error));

    throw new Error(error);
  }
};

const loadMoreHotel = (items, itemsIds) => (dispatch) => {
  const responseItemsIds = items.map((el) => el.id);
  const newItemId = arrayDiff(itemsIds, responseItemsIds);

  if (newItemId.length === 0) {
    return;
  }

  dispatch(getLikedHotels(newItemId, true));
};

const mapDispatchToProps = (dispatch) => ({
  getLikedHotels: (itemsIds) => dispatch(getLikedHotels(itemsIds)),
  loadMoreHotel: (items, itemsIds) => dispatch(loadMoreHotel(items, itemsIds))
});

const mapStateToProps = (state) => ({
  itemsIds: state.likedHotels.itemsIds,
  items: state.likedHotels.items
});

export default connect(mapStateToProps, mapDispatchToProps)(LikedHotelsComponent);
