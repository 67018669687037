import React from 'react';
import PropTypes from 'prop-types';
import SvgInline from 'common/base-components/SvgInline';
import getImageSrc from 'helpers/getImageSrc';
import ThumbUpIcon from './assets/ThumbUpIcon.svg';

import styles from './assets/ProgressCircle.module.scss';

const ProgressCircle = ({ percent, icon }) => {
  const progressCircleRef = React.useRef(null);

  const calculateProgress = () => {
    const RADIUS = 31;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    const progress = 1 - percent / 100;
    const dashoffset = CIRCUMFERENCE * (1 - progress);
    const rotateDeg = Math.ceil(90 + dashoffset - percent / 2 / Math.PI);

    progressCircleRef.current.style.strokeDasharray = CIRCUMFERENCE;
    progressCircleRef.current.style.strokeDashoffset = dashoffset;
    progressCircleRef.current.style.transform = `rotate(${rotateDeg}deg)`;
  };

  React.useEffect(() => {
    calculateProgress();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.progressCircleWrapper}>
        <div className={styles.icon}>
          <SvgInline url={getImageSrc(icon)} />
        </div>
        <svg className={styles.progressWrapper} width="66" height="66">
          <defs>
            <linearGradient id="linearColor" x1="100%" y1="0%" x2="0%" y2="0%">
              <stop offset="0%" stopColor="#4c5192" />
              <stop offset="100%" stopColor="#7db9fb" />
            </linearGradient>
          </defs>
          <circle
            className={styles.progressBar}
            stroke="url(#linearColor)"
            strokeWidth="4"
            fill="transparent"
            r="31"
            cx="33"
            cy="33"
          />
          <circle
            ref={progressCircleRef}
            className={styles.progress}
            stroke="white"
            strokeWidth="4"
            fill="transparent"
            r="31"
            cx="33"
            cy="33"
          />
        </svg>
      </div>
      <div className={styles.percentBlock}>
        <img src={ThumbUpIcon} alt="ThumbUp" />
        <span>{percent}%</span>
      </div>
    </div>
  );
};

ProgressCircle.propTypes = {
  percent: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired
};

export default ProgressCircle;
