import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';

import styles from './assets/Title.module.scss';

const Title = ({ children, variant, className }) => (
  <Typography variant={variant} className={cn(styles.title, className)}>
    {children}
  </Typography>
);

Title.defaultProps = {
  variant: 'h5',
  className: undefined
};

Title.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

export default Title;
