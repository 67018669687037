import reducerRegistry from 'reducerRegistry';

// CONSTS

const initialState = {};

// ACTIONS

// REDUCER
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case '':
      return { ...state };
    default:
      return state;
  }
};

reducerRegistry.register('home', homeReducer);
