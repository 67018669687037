import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import Button from 'common/base-components/Button';
import FiltersByFactsBlocks from './FiltersByFactsBlocks';

import styles from './assets/FiltersByFactsDialog.module.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
Transition.displayName = 'Transition';

const FiltersByFactsDialog = ({ componentRef, onApply, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const factsBlocksRef = React.useRef(null);

  const handleChangeVisibility = (isOpenDialog) => setIsOpen(isOpenDialog);

  const handleApply = () => {
    const selectedFactsKeys = Object.keys(factsBlocksRef.current.selectedItems).map((el) => el);

    onApply(selectedFactsKeys);
    setIsOpen(false);
  };

  const handleReset = () => {
    factsBlocksRef.current.handleReset();
  };

  React.useImperativeHandle(componentRef, () => ({
    handleChangeVisibility
  }));

  return (
    <Dialog
      fullScreen
      keepMounted
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.underlay} />
        <header className={styles.header}>
          <div className={styles.resetBtnWrapper}>
            <IconButton edge="start" color="inherit" onClick={handleReset}>
              <ReplayIcon className={styles.replayIcon} />
              Сбросить
            </IconButton>
          </div>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleChangeVisibility(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </header>
      </div>
      <div className={styles.content}>
        <FiltersByFactsBlocks ref={factsBlocksRef} {...props} />
      </div>
      <footer className={styles.footer}>
        <Button type="apply" onClick={handleApply}>
          Применить
        </Button>
      </footer>
    </Dialog>
  );
};

FiltersByFactsDialog.defaultProps = {
  onApply: () => {}
};

FiltersByFactsDialog.propTypes = {
  onApply: PropTypes.func,
  componentRef: PropTypes.any.isRequired
};

export default React.forwardRef((props, ref) => (
  <FiltersByFactsDialog componentRef={ref} {...props} />
));
