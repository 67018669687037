import React from 'react';
import PropTypes from 'prop-types';
import LoadingProgress from 'common/base-components/LoadingProgress';
import HotelsList from './HotelsList';

const Hotels = ({ loading, items, selectedDelightersKeys }) => (
  <div>
    <HotelsList items={items} selectedDelightersKeys={selectedDelightersKeys} />
    {loading && <LoadingProgress />}
  </div>
);

Hotels.defaultProps = {
  selectedDelightersKeys: undefined
};

Hotels.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDelightersKeys: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool.isRequired
};

export default Hotels;
