import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import styles from './assets/FullInfoDialog.module.scss';

const Transition = React.forwardRef(({ direction, ...props }, ref) => (
  <Slide direction={direction} ref={ref} {...props} />
));
Transition.displayName = 'Transition';

Transition.propTypes = {
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']).isRequired
};

const FullInfoDialog = ({
  open,
  onClose,
  title,
  direction,
  btnText,
  renderRightSection,
  children
}) => (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
    TransitionProps={{
      direction
    }}
  >
    <div className={styles.headerWrapper}>
      <div className={styles.underlay} />
      <header className={styles.header}>
        <div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            className={styles.returnBackBtn}
            onClick={onClose}
          >
            <ArrowBackIosIcon />
            {btnText && `${' '}${btnText}`}
          </IconButton>
        </div>
        {title && (
          <Typography variant="body1" className={styles.headerTitle}>
            {title}
          </Typography>
        )}
        <div />
        {renderRightSection && <div style={{ flex: 0 }}>{renderRightSection()}</div>}
      </header>
    </div>
    {children}
  </Dialog>
);

FullInfoDialog.defaultProps = {
  direction: 'left',
  btnText: 'Отель',
  title: undefined,
  renderRightSection: undefined
};

FullInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  btnText: PropTypes.string,
  renderRightSection: PropTypes.func,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down'])
};

export default FullInfoDialog;
