import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as LikeIcon } from './assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from './assets/icons/dislike.svg';

import styles from './assets/Recommendation.module.scss';

const Recommendation = ({ recommendationState, setRecommendationState, hotelName }) => {
  const handleLikeButtonClick = React.useCallback(
    (isLiked) => {
      setRecommendationState((prevProps) => ({ ...prevProps, leaveHotel: isLiked }));
    },
    [setRecommendationState]
  );

  const handleRecommendationChange = React.useCallback(
    (event) => {
      setRecommendationState((prevProps) => ({ ...prevProps, recommendation: event.target.value }));
    },
    [setRecommendationState]
  );

  return (
    <div>
      <h4>Оставить отель в AskBee?</h4>
      <h5>{hotelName}</h5>
      <div className={styles.likeBlock}>
        <IconButton
          onClick={() => handleLikeButtonClick(true)}
          classes={{ label: styles.buttonLabel }}
          className={cn(recommendationState.leaveHotel && styles.activeLikedButton)}
        >
          <LikeIcon />
          <span className={styles.buttonDescription}>Да</span>
        </IconButton>
        <IconButton
          onClick={() => handleLikeButtonClick(false)}
          classes={{ label: styles.buttonLabel }}
          className={cn(
            recommendationState.leaveHotel !== null &&
              recommendationState.leaveHotel === false &&
              styles.activeDislikedButton
          )}
        >
          <DislikeIcon />
          <span className={styles.buttonDescription}>Нет</span>
        </IconButton>
      </div>
      <textarea
        name="recommendation"
        value={recommendationState.recommendation}
        onChange={handleRecommendationChange}
        placeholder="Оставить рекомендацию..."
      />
    </div>
  );
};

Recommendation.propTypes = {
  recommendationState: PropTypes.object.isRequired,
  setRecommendationState: PropTypes.func.isRequired,
  hotelName: PropTypes.string.isRequired
};

export default Recommendation;
