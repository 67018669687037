import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import capitalize from 'helpers/capitalize';

function customWithTranslation(ns, options = {}) {
  return function Extend(WrappedComponent) {
    const I18nextWithTranslation = (props) => {
      const [currentLng, setCurrentLng] = React.useState(i18next.language ?? 'ru');

      i18next.on('languageChanged', (lng) => {
        setCurrentLng(lng);
      });

      const tBack = (object, key) => {
        const capitalizeKey = capitalize(key);

        return object[`${currentLng}${capitalizeKey}`] ?? object[key];
      };

      const extendedProps = {
        ...props,
        tBack
      };

      return React.createElement(WrappedComponent, extendedProps);
    };

    return withTranslation(ns, options)(I18nextWithTranslation);
  };
}

export default customWithTranslation;
