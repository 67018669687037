import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import styles from '../assets/MenuList.module.scss';

const MenuListComponent = ({ isLoggedIn, profile, onActivateCodeClick }) => (
  <>
    {isLoggedIn && !profile.active && (
      <List component="nav" className={styles.list}>
        <ListItem button className={styles.listItem} onClick={onActivateCodeClick}>
          <ListItemText primary="Активировать код" />
          <KeyboardArrowRightIcon className={styles.rightIcon} />
        </ListItem>
      </List>
    )}
    <p className={styles.subTitle}>Cвязаться по Whatsapp: +79166633652</p>
    <List component="nav" className={styles.list}>
      <ListItem button className={styles.listItem} component={Link} to="/terms-of-use">
        <ListItemText primary="Условия использования" />
        <KeyboardArrowRightIcon className={styles.rightIcon} />
      </ListItem>
      <ListItem button className={styles.listItem} component={Link} to="/privacy-policy">
        <ListItemText primary="Политика конфиденциальности" />
        <KeyboardArrowRightIcon className={styles.rightIcon} />
      </ListItem>
    </List>
  </>
);

MenuListComponent.defaultProps = {
  profile: {}
};

MenuListComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  onActivateCodeClick: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    active: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
    invitedCode: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string
  })
};

export default MenuListComponent;
