import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './assets/SpaceBetweenBlock.module.scss';

const SpaceBetweenBlock = ({ children, className }) => (
  <div className={cn(styles.spaceBetweenBlock, className)}>{children}</div>
);

SpaceBetweenBlock.defaultProps = {
  className: ''
};

SpaceBetweenBlock.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
};

export default SpaceBetweenBlock;
