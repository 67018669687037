import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchInput from 'common/base-components/SearchInput';
import debounce from 'lodash/debounce';

import styles from '../assets/ComboBox.module.scss';

const ComboBoxComponent = ({
  onGetOptions,
  searchInputClassName,
  placeholder,
  apiPath,
  onSelectValue,
  initialValue,
  componentRef
}) => {
  const memoizedInitialValue = React.useMemo(() => initialValue, []);
  const autocompleteRef = React.useRef();
  const [options, setOptions] = React.useState([]);

  const getOptions = async (searchValue) => {
    if (searchValue.length < 2) {
      setOptions([]);

      return;
    }

    try {
      const data = await onGetOptions(apiPath, searchValue);

      setOptions(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleReset = () => {
    autocompleteRef.current.querySelector('button[aria-label="Clear"]')?.click();
  };

  React.useImperativeHandle(componentRef, () => ({
    handleReset
  }));

  const debouncedGetOptions = useCallback(debounce(getOptions, 400), []);

  return (
    <div className={styles.autocompleteWrapper}>
      <Autocomplete
        freeSolo
        disablePortal
        ref={autocompleteRef}
        defaultValue={memoizedInitialValue}
        blurOnSelect="touch"
        classes={{
          root: styles.root,
          option: styles.option,
          listbox: styles.listbox,
          paper: styles.paper,
          popperDisablePortal: styles.popperDisablePortal
        }}
        onChange={(event, newValue) => {
          onSelectValue(newValue ?? undefined);
        }}
        onInputChange={(_, value, reason) => {
          if (reason !== 'input') {
            return;
          }

          debouncedGetOptions(value);
        }}
        options={options}
        filterOptions={(filterOptions) => filterOptions}
        renderInput={(searchInputProps) => (
          <SearchInput
            searchInputProps={searchInputProps}
            inputClassName={searchInputClassName}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
};

ComboBoxComponent.defaultProps = {
  searchInputClassName: undefined,
  initialValue: '',
  placeholder: '',
  componentRef: undefined
};

ComboBoxComponent.propTypes = {
  onGetOptions: PropTypes.func.isRequired,
  searchInputClassName: PropTypes.string,
  onSelectValue: PropTypes.func.isRequired,
  apiPath: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  componentRef: PropTypes.any,
  placeholder: PropTypes.string
};

export default React.forwardRef((props, ref) => (
  <ComboBoxComponent componentRef={ref} {...props} />
));
