import {
  DELIGHTERS_GET_DELIGHTERS_BEGIN,
  DELIGHTERS_GET_DELIGHTERS_SUCCESS,
  DELIGHTERS_GET_DELIGHTERS_ERROR
} from './actionTypes';

export const getDelightersBegin = () => ({
  type: DELIGHTERS_GET_DELIGHTERS_BEGIN
});

export const getDelightersSuccess = (payload) => ({
  type: DELIGHTERS_GET_DELIGHTERS_SUCCESS,
  payload
});

export const getDelightersError = (payload) => ({
  type: DELIGHTERS_GET_DELIGHTERS_ERROR,
  payload
});
