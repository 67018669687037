import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Button from '@material-ui/core/Button';
import SignInDialog from './SignInDialog';

import styles from './assets/SignInButton.module.scss';

const SignInButton = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedSearchLocation = React.useMemo(
    () => qs.parse(location.search, { arrayFormat: 'bracket' }),
    [location]
  );
  const [isDialogOpened, setIsDialogOpened] = React.useState(
    Boolean(parsedSearchLocation.signInModalOpened)
  );

  const handleLoginClick = React.useCallback(() => {
    const searchString = qs.stringify(
      { ...parsedSearchLocation, signInModalOpened: true },
      { arrayFormat: 'bracket' }
    );

    history.push({
      ...location,
      search: searchString
    });

    setIsDialogOpened(true);
  }, []);

  const handleLogIn = React.useCallback(async (provider) => {
    try {
      const profileWasChecked = localStorage.getItem('profileWasChecked');
      const { _env_: env } = window;

      const searchString = qs.stringify(
        { ...parsedSearchLocation, signInModalOpened: undefined },
        { arrayFormat: 'bracket' }
      );

      if (profileWasChecked) {
        localStorage.removeItem('profileWasChecked');
      }

      history.push({
        ...location,
        search: searchString
      });

      window.location.href = `${env.ENDPOINT}/users/login/${provider}`;
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const handleDialogClose = React.useCallback(() => {
    const searchString = qs.stringify(
      { ...parsedSearchLocation, signInModalOpened: undefined },
      { arrayFormat: 'bracket' }
    );

    history.push({
      ...location,
      search: searchString
    });

    setIsDialogOpened(false);
  });

  return (
    <>
      <Button onClick={handleLoginClick} variant="outlined" className={styles.loginBtn}>
        Войти
      </Button>
      <SignInDialog open={isDialogOpened} onClose={handleDialogClose} onLogIn={handleLogIn} />
    </>
  );
};

export default SignInButton;
