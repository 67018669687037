import React from 'react';
import axios from 'axios';
import qs from 'query-string';
import ScrollableHotelsSection from 'common/base-components/ScrollableHotelsSection';
import transformCompilationsHotels from 'helpers/transformCompilationsHotels';

const CompilationsHotels = () => {
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getCompilationsHotels = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get('/hotels/compilations', {
        params: {
          nameNotIn: 'Популярные отели'
        },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const compilationsHotels = transformCompilationsHotels(data);

      setItems(compilationsHotels);
      setIsLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getCompilationsHotels();
  }, []);

  return (
    <>
      {Object.keys(items).map((compilationKey) => (
        <ScrollableHotelsSection
          key={compilationKey}
          title={compilationKey}
          items={items[compilationKey]}
          loading={isLoading}
        />
      ))}
    </>
  );
};

export default CompilationsHotels;
