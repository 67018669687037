import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import RecentlyViewedHotelsComponent from '../components/RecentlyViewedHotelsComponent';
import {
  getRecentlyViewedHotelsBegin,
  getRecentlyViewedHotelsSuccess,
  getRecentlyViewedHotelsError
} from '../actions';
import '../index';

const getRecentlyViewedHotels = (itemsIds) => async (dispatch) => {
  try {
    dispatch(getRecentlyViewedHotelsBegin());

    const { data } = await axios.get('/hotels/list', {
      params: {
        id: itemsIds
      },
      paramsSerializer: (params) => qs.stringify(params)
    });

    dispatch(getRecentlyViewedHotelsSuccess({ items: data }));
  } catch (error) {
    dispatch(getRecentlyViewedHotelsError(error));

    throw new Error(error);
  }
};

const mapDispatchToProps = (dispatch) => ({
  getRecentlyViewedHotels: (itemsIds) => dispatch(getRecentlyViewedHotels(itemsIds))
});

const mapStateToProps = (state) => ({
  items: state.recentlyViewedHotels.items,
  loading: state.recentlyViewedHotels.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewedHotelsComponent);
