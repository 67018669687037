import {
  LIKED_HOTELS_LIKE_HOTEL,
  LIKED_HOTELS_UNLIKE_HOTEL,
  LIKED_HOTELS_GET_LIKED_HOTELS_BEGIN,
  LIKED_HOTELS_GET_LIKED_HOTELS_SUCCESS,
  LIKED_HOTELS_GET_LIKED_HOTELS_ERROR
} from './actionTypes';

export const likeHotel = (hotelId) => {
  const likedHotelsIdsStorage = JSON.parse(localStorage.getItem('likedHotelsIds')) ?? [];
  const itemsIds = [...likedHotelsIdsStorage, hotelId];

  localStorage.setItem('likedHotelsIds', JSON.stringify(itemsIds));

  return {
    type: LIKED_HOTELS_LIKE_HOTEL,
    payload: itemsIds
  };
};

export const unlikeHotel = (hotelId) => {
  const likedHotelsIdsStorage = JSON.parse(localStorage.getItem('likedHotelsIds')) ?? [];
  const itemsIds = likedHotelsIdsStorage.filter((el) => el !== hotelId);

  localStorage.setItem('likedHotelsIds', JSON.stringify(itemsIds));

  return {
    type: LIKED_HOTELS_UNLIKE_HOTEL,
    payload: itemsIds
  };
};

export const getLikedHotelsBegin = () => ({
  type: LIKED_HOTELS_GET_LIKED_HOTELS_BEGIN
});

export const getLikedHotelsSuccess = (payload) => ({
  type: LIKED_HOTELS_GET_LIKED_HOTELS_SUCCESS,
  payload
});

export const getLikedHotelsError = (payload) => ({
  type: LIKED_HOTELS_GET_LIKED_HOTELS_ERROR,
  payload
});
