import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import transformCompilationsHotels from 'helpers/transformCompilationsHotels';
import CompilationHotelsByNameComponent from '../components/CompilationHotelsByNameComponent';

const onGetHotels = async (compilationName) => {
  try {
    const { data } = await axios.get('/hotels/compilations', {
      params: {
        nameIn: compilationName
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
    const hotels = transformCompilationsHotels(data)[compilationName] ?? [];

    return hotels;
  } catch (error) {
    throw new Error(error);
  }
};

const mapDispatchToProps = () => ({
  onGetHotels: (compilationName) => onGetHotels(compilationName)
});

export default connect(null, mapDispatchToProps)(CompilationHotelsByNameComponent);
