import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as ArrowRightIcon } from './assets/arrowRightIcon.svg';

import styles from './assets/ActivateCodeForm.module.scss';

const ActivateCodeForm = ({ onActivateSuccess }) => {
  const formikRef = React.useRef(null);
  const [isActivating, setIsActivating] = React.useState(false);
  const validate = React.useCallback((values) => {
    const errors = {};

    if (!values.code) {
      errors.code = 'Обязательное поле!';
    }

    return errors;
  }, []);

  const handleSubmitForm = React.useCallback(async (values) => {
    try {
      setIsActivating(true);

      await axios.post(`/users/activate/${values.code}`);

      setIsActivating(false);
      onActivateSuccess();
    } catch (error) {
      formikRef.current.setErrors({ code: 'Неправильный код' });

      setIsActivating(false);
    }
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ code: '' }}
      validate={validate}
      onSubmit={handleSubmitForm}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(styles.fieldWrapper, touched.code && errors.code && styles.fieldError)}
          >
            <Field name="code" type="text" placeholder="efvDZomlQZ" className={styles.codeField} />
            <Button
              classes={{ root: styles.submitButton, disabled: styles.disabledSubmitButton }}
              type="submit"
              disabled={isActivating}
            >
              {isActivating ? <CircularProgress size={20} /> : <ArrowRightIcon />}
            </Button>
          </div>
          {touched.code && errors.code && <p className={styles.errorLabel}>{errors.code}</p>}
        </Form>
      )}
    </Formik>
  );
};

ActivateCodeForm.propTypes = {
  onActivateSuccess: PropTypes.func.isRequired
};

export default ActivateCodeForm;
