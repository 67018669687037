import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';
import FixedFiltersButton from 'common/base-components/FixedFiltersButton';
import IconButton from '@material-ui/core/IconButton';
import RoomIcon from '@material-ui/icons/Room';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GoogleMap from 'common/base-components/GoogleMap';

import styles from '../assets/HotelsMapComponent.module.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
Transition.displayName = 'Transition';

const HotelsMapComponent = ({
  history,
  location,
  locationName,
  facts,
  delighters,
  likedHotelsIds
}) => {
  const [isDialogVisible, setIsDialogVisible] = React.useState(location.hash === '#MAPVIEW');
  const [isLoading, setIsLoading] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const getItems = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get('/hotels', {
        params: {
          locationName,
          delight: delighters,
          like: likedHotelsIds,
          fact: facts,
          photos: false,
          limit: 1000
        },
        paramsSerializer: (params) => qs.stringify(params)
      });

      setIsLoading(false);
      setItems(data);
    } catch (error) {
      setIsLoading(false);

      throw new Error(error);
    }
  }, [locationName, delighters, likedHotelsIds, facts]);

  const handleShowMapClick = React.useCallback(() => {
    history.push({
      ...location,
      hash: '#MAPVIEW'
    });

    setIsDialogVisible(true);
  }, [history]);

  const handleClose = () => {
    history.push({
      ...location,
      hash: ''
    });

    setItems([]);
    setIsDialogVisible(false);
  };

  const renderDialogContent = React.useCallback(() => {
    if (isLoading) {
      return (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (!isLoading && !items.length) {
      return (
        <div className={styles.loadingWrapper}>
          <p>Empty result</p>
        </div>
      );
    }

    return <GoogleMap items={items} withInfoModal />;
  }, [isLoading, items]);

  return (
    <>
      <FixedFiltersButton onClick={handleShowMapClick} className={styles.button}>
        <RoomIcon />
        Карта
      </FixedFiltersButton>
      <Dialog
        fullScreen
        open={isDialogVisible}
        onClose={handleClose}
        onEnter={() => {
          getItems();
        }}
        TransitionComponent={Transition}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.underlay} />
          <header className={styles.header}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              className={styles.returnBackBtn}
              onClick={handleClose}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </header>
        </div>
        <div className={styles.content}>{renderDialogContent()}</div>
      </Dialog>
    </>
  );
};

HotelsMapComponent.defaultProps = {
  locationName: undefined,
  facts: undefined,
  delighters: undefined,
  likedHotelsIds: undefined
};

HotelsMapComponent.propTypes = {
  locationName: PropTypes.string,
  facts: PropTypes.arrayOf(PropTypes.string),
  delighters: PropTypes.arrayOf(PropTypes.string),
  likedHotelsIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(HotelsMapComponent);
