import { connect } from 'react-redux';
import { likeHotel, unlikeHotel } from 'common/compound-components/LikedHotels/actions';
import 'common/compound-components/LikedHotels';
import LikeButtonComponent from '../components/LikeButtonComponent';

const mapDispatchToProps = (dispatch) => ({
  likeHotel: (payload) => dispatch(likeHotel(payload)),
  unlikeHotel: (payload) => dispatch(unlikeHotel(payload))
});

const mapStateToProps = (state) => ({
  likedHotelsIds: state.likedHotels.itemsIds
});

export default connect(mapStateToProps, mapDispatchToProps)(LikeButtonComponent);
