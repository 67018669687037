import React from 'react';
import PropTypes from 'prop-types';
import FullInfoDialog from 'common/base-components/FullInfoDialog';
import useUserProfile from 'common/compound-components/Header/hook/useUserProfile';
import ActivateCodeForm from './ActivateCodeForm';

import styles from './assets/ActivateCodeDialog.module.scss';

const ActivateCodeDialogComponent = ({ open, onClose }) => {
  const { onActivateSuccess } = useUserProfile();

  const handleActivateSuccess = React.useCallback(() => {
    onActivateSuccess();
    onClose();
  }, []);

  return (
    <FullInfoDialog
      fullScreen
      open={open}
      onClose={() => {
        onClose();
      }}
      btnText={null}
      title={null}
    >
      <div className={styles.root}>
        <h3 className={styles.title}>Активировать код</h3>
        <ActivateCodeForm onActivateSuccess={handleActivateSuccess} />
        <div className={styles.infoBlock}>
          <p>Приглашения должны выглядеть так:</p>
          <p>efvDZomlQZ или https://askbee.ru/efvDZomlQZ</p>
        </div>
      </div>
    </FullInfoDialog>
  );
};

ActivateCodeDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ActivateCodeDialogComponent;
