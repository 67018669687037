import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from 'common/base-components/Button';
import useUserProfile from 'common/compound-components/Header/hook/useUserProfile';
import Dialog from './Dialog';

import styles from './assets/AddFeedback.module.scss';

// for test
// const initialFeedback = {
//   negativeComments: ['Грязно', 'Нет тёплой воды'],
//   positiveDelights: [
//     {
//       key: 'FOOD',
//       comment: 'All good',
//       subDelightKeys: ['ДИЕТИЧЕСКОЕ_ПИТАНИЕ', 'БЫСТРО']
//     },
//     {
//       key: 'POOL',
//       comment: '',
//       subDelightKeys: ['УДОБНЫЕ_ЛЕЖАКИ/ЗОНТИКИ']
//     },
//     {
//       key: 'KIDSCLUB',
//       comment: '',
//       subDelightKeys: ['МЕЛКИЙ_БАССЕЙН']
//     },
//     {
//       key: 'AROUND',
//       comment: '',
//       subDelightKeys: ['БЕЗОПАСНО']
//     }
//   ],
//   leaveHotel: true,
//   recommendation: 'Good'
// };

const AddFeedback = ({ hotelId, hotelName, delighters, isDisabled }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUserFeedbackLoading, setIsUserFeedbackLoading] = React.useState(null);
  const [userFeedback, setUserFeedback] = React.useState({});
  const { resetProfile } = useUserProfile();

  React.useEffect(async () => {
    try {
      setIsUserFeedbackLoading(true);

      const { data } = await axios.get(`/hotels/${hotelId}/user-feedback`);

      setUserFeedback(data);
      setIsUserFeedbackLoading(false);
    } catch (error) {
      setIsUserFeedbackLoading(false);

      if (error.response?.status === 401) {
        resetProfile();
      }
    }
  }, []);

  const handleFeebackAdded = React.useCallback((feedback) => {
    setUserFeedback(feedback);
  }, []);

  if (isUserFeedbackLoading) {
    return null;
  }

  return (
    <div>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Button
          className={styles.button}
          variant="outlined"
          disabled={isDisabled}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {Object.keys(userFeedback).length === 0 ? 'Оставить отзыв' : 'Изменить отзыв'}
        </Button>
        {isModalOpen && (
          <Dialog
            initialUserFeedback={userFeedback}
            hotelId={hotelId}
            hotelName={hotelName}
            delighters={delighters}
            isModalOpen={isModalOpen}
            onAdd={handleFeebackAdded}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

AddFeedback.defaultProps = {
  isDisabled: true
};

AddFeedback.propTypes = {
  hotelId: PropTypes.number.isRequired,
  hotelName: PropTypes.string.isRequired,
  delighters: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool
};

export default AddFeedback;
