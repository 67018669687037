import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import cn from 'classnames';
import useDidMountEffect from 'helpers/useDidMountEffectHook';
import Header from 'common/compound-components/Header/components/HeaderComponent';
import Hotels from 'common/base-components/Hotels';
import FiltersByFacts from 'common/compound-components/FiltersByFacts';
import LoadMoreButton from 'common/base-components/LoadMoreButton';
import SwipeableSearchParamsPanel from 'common/base-components/SwipeableSearchParamsPanel';
import CompilationHotelsByName from 'common/compound-components/CompilationHotelsByName';
import LikedHotels from 'common/compound-components/LikedHotels/containers/LikedHotelsContainer';
import RecentlyViewedHotels from 'common/compound-components/RecentlyViewedHotels/containers/RecentlyViewedHotelsContainer';
import HotelsMapComponent from './HotelsMapComponent';

import styles from '../assets/SearchResultsComponent.module.scss';

const SearchResultsComponent = ({
  location,
  history,
  getHotels,
  resetState,
  loading,
  items,
  hasMore
}) => {
  const parsedSearchParams = React.useMemo(
    () => qs.parse(location.search, { arrayFormat: 'bracket' }),
    [location.search]
  );

  useEffect(() => {
    getHotels(parsedSearchParams);

    return () => {
      resetState();
    };
  }, []);

  useDidMountEffect(() => {
    getHotels(parsedSearchParams);
  }, [location.search]);

  const onFiltersByFactsApply = (selectedItems) => {
    const querySettings = {
      ...parsedSearchParams,
      facts: selectedItems
    };
    const searchString = qs.stringify(querySettings, { arrayFormat: 'bracket' });

    history.push({
      pathname: '/search-results',
      search: searchString
    });
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    getHotels(parsedSearchParams, true);
  };

  const { delighters, likedHotelsIds, facts, locationName } = parsedSearchParams;

  return (
    <div className={cn(styles.wrapper, 'inner-container')}>
      <Header />
      <Hotels items={items} selectedDelightersKeys={delighters} loading={loading} />
      <footer className={styles.footer}>
        {items.length === 0 && <h2>Ничего не найдено</h2>}
        {items.length > 0 && <LoadMoreButton onClick={handleLoadMore} disabled={!hasMore} />}
        <div className={styles.compilationsBlocks}>
          <LikedHotels />
          <RecentlyViewedHotels />
          <CompilationHotelsByName compilationName="Лучшие пляжи" size="small" />
        </div>
      </footer>
      <FiltersByFacts onApply={onFiltersByFactsApply} initialValues={facts} />
      <HotelsMapComponent
        locationName={locationName}
        delighters={delighters}
        likedHotelsIds={likedHotelsIds}
        facts={facts}
      />
      <SwipeableSearchParamsPanel
        title="Изменить параметры поиска"
        history={history}
        parsedSearchParams={parsedSearchParams}
        initialValues={{
          delighters,
          likedHotelsIds,
          locationName
        }}
      />
    </div>
  );
};

SearchResultsComponent.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  getHotels: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired
};

export default SearchResultsComponent;
