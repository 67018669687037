import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import Button from 'common/base-components/Button';
import {
  setSearchParamsPanelVisibility,
  setIsOnBoardPageWasApplied
} from '../../../store/settings/actions';
import StepCard from './StepCard';

import step1Cover from './assets/images/step1.png';
import step2Cover from './assets/images/step2.png';
import step3Cover from './assets/images/step3.png';
import step4Cover from './assets/images/step4.png';

import styles from './assets/OnBoardStepper.module.scss';

const Step = ({ isActive, isFinal, onChange, children }) => {
  React.useEffect(() => {
    if (isActive) {
      onChange(isFinal);
    }
  }, [isActive]);

  return (
    <div className={cn(styles.step, isActive && styles.activeStep)} aria-hidden="true">
      {children}
    </div>
  );
};

Step.defaultProps = {
  isActive: false,
  isFinal: false,
  onChange: () => {}
};

Step.propTypes = {
  isActive: PropTypes.bool,
  isFinal: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any.isRequired
};

const Stepper = ({ rootRef, children }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isFinalStep, setIsFinalStep] = React.useState(false);
  const paginationChildren = React.Children.toArray(children).filter(
    (child) => !child.props.isFinal
  );

  const handleStepChange = (isFinal) => {
    setIsFinalStep(isFinal);
  };

  return (
    <div className={styles.steps}>
      <div className={styles.stepsWrapper}>
        {React.Children.map(children, (child, index) => {
          const isActive = currentStep === index;

          return React.cloneElement(child, { isActive, onChange: handleStepChange });
        })}
      </div>
      <div className={styles.stepperInfo}>
        {!isFinalStep && (
          <>
            <ul className={styles.pagination}>
              {paginationChildren.map((child, index) => (
                <li
                  key={child.key}
                  className={cn(index === currentStep && styles.isActivePaginationItem)}
                />
              ))}
            </ul>
            <Button
              className={cn(styles.button)}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            >
              Далее
            </Button>
          </>
        )}
        {isFinalStep && (
          <>
            <Button
              className={cn(styles.button, styles.fullWidthButton)}
              onClick={() => {
                rootRef.current.style.opacity = 0; // eslint-disable-line

                dispatch(setSearchParamsPanelVisibility(true));

                setTimeout(() => {
                  dispatch(setIsOnBoardPageWasApplied(true));
                }, 300);
              }}
            >
              Искать свой отдых мечты
            </Button>
            <Button
              className={cn(styles.button, styles.fullWidthButton, styles.skipButton)}
              variant="outlined"
              onClick={() => {
                rootRef.current.style.opacity = 0; // eslint-disable-line

                setTimeout(() => {
                  dispatch(setIsOnBoardPageWasApplied(true));
                }, 300);
              }}
            >
              Пропустить
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  rootRef: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

const OnBoardStepper = () => {
  const rootRef = React.useRef(null);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div ref={rootRef} className={styles.root}>
      <Stepper rootRef={rootRef}>
        <Step>
          <StepCard
            centerCover
            coverSrc={step1Cover}
            title="Твой мир путешествий"
            description="Найди отдых мечты среди лучших отелей в своем сервисе"
          />
        </Step>
        <Step>
          <StepCard
            coverSrc={step2Cover}
            title="Выбери свои Delighters"
            description="Delighters - специализации отелей в которых они соревнуются"
          />
        </Step>
        <Step>
          <StepCard
            centerCover
            coverSrc={step3Cover}
            title="Делись впечатлением"
            description="Оставляй свою оценку сервисам и мир путешествий станет лучше!"
          />
        </Step>
        <Step isFinal>
          <StepCard
            centerCover
            coverSrc={step4Cover}
            title="Твой мир путешествий"
            description="Найди отдых мечты среди лучших отелей в своем сервисе"
          />
        </Step>
      </Stepper>
    </div>
  );
};

export default OnBoardStepper;
