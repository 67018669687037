import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';

import styles from '../assets/FeedbackCard.module.scss';

const FeedbackCardComponent = ({ item, className, onCardClick, isFull, shouldAddTitleCaption }) => {
  const createdAtDate = React.useMemo(() => moment(item.createdAt).format('D MMMM YYYY'), [
    item.createdAt
  ]);

  const renderCaptionByEmotion = React.useCallback((emotion, delighter) => {
    if (!shouldAddTitleCaption) {
      return delighter?.ruName;
    }

    if (emotion === 'NEUTRAL') {
      return 'Рекомендую';
    }

    if (emotion === 'NEGATIVE') {
      return 'Не понравилось';
    }

    return `Понравилось: ${delighter?.ruName.toLowerCase()}`;
  }, []);

  const handleCardClick = () => {
    if (isFull) {
      return;
    }

    onCardClick(item);
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className={cn(styles.wrapper, !isFull && styles.notFullWrapper, className)}
      data-emotion={item.emotion}
      onKeyPress={handleCardClick}
      onClick={handleCardClick}
    >
      <h5 className={styles.title}>{renderCaptionByEmotion(item.emotion, item.delight)}</h5>
      <div className={styles.textWrapper}>
        <div className={styles.text}>{item.text}</div>
      </div>
      <SpaceBetweenBlock className={styles.header}>
        <div className={styles.like}>
          <span>{item.likeCount}</span> <ThumbUpIcon />
        </div>
        <div className={styles.date}>{createdAtDate}</div>
      </SpaceBetweenBlock>
    </div>
  );
};

FeedbackCardComponent.defaultProps = {
  className: '',
  onCardClick: () => {},
  isFull: false,
  shouldAddTitleCaption: false
};

FeedbackCardComponent.propTypes = {
  item: PropTypes.shape({
    createdAt: PropTypes.string,
    emotion: PropTypes.string,
    likeCount: PropTypes.number,
    text: PropTypes.string,
    delight: PropTypes.object
  }).isRequired,
  className: PropTypes.string,
  onCardClick: PropTypes.func,
  isFull: PropTypes.bool,
  shouldAddTitleCaption: PropTypes.bool
};

export default FeedbackCardComponent;
