import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import ComboBoxComponent from '../components/ComboBoxComponent';

const onGetOptions = async (apiPath, searchValue) => {
  try {
    const { data } = await axios.get(apiPath, {
      params: {
        filter: searchValue
      },
      paramsSerializer: (params) => qs.stringify(params)
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const mapDispatchToProps = () => ({
  onGetOptions: (apiPath, searchValue) => onGetOptions(apiPath, searchValue)
});

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ComboBoxComponent);
