import React from 'react';
import Header from 'common/compound-components/Header/components/HeaderComponent';
import DomainLink from '../TermsOfUse/DomainLink';

const PrivacyPolicy = () => (
  <div className="text-content">
    <Header />
    <h2 className="first">Положение о конфиденциальности и использовании файлов cookie</h2>
    <p>
      Команда AskBee ценит Вас и Вашу конфиденциальность. Вы оказываете нам доверие, когда
      предоставляете нам свои персональные данные, при посещении сайта <DomainLink /> или когда
      используете наши услуги. Мы прилагаем все усилия, чтобы сохранить ваши личные данные в
      безопасности. Рекомендуем внимательно ознакомиться с данным Документом и больше узнать о нашей
      политике конфиденциальности. На нашем Веб-сайте AskBee мы предоставляем он-лайн услуги по
      организации отдыха и путешествий, на основе обработанных отзывов предоставляем пользователям
      рекомендации по поиску отеля или туристического пакета, включая консультации и поддержку наших
      клиентов на отдыхе.
    </p>
    <p>
      Положение о конфиденциальности распространяется на все виды информации о клиентах, которую мы
      собираем через наши платформы или по другим каналам, связанным с этими платформами.
    </p>
    <p>
      Мы можем время от времени изменять Положение о конфиденциальности, поэтому рекомендуем вам
      регулярно проверять эту страницу. Если мы внесем в Положение о конфиденциальности такие
      изменения, которые непосредственно вас затронут, мы сообщим вам о таких изменениях до
      вступления их в силу. Если Вы не согласны с этим Положением о конфиденциальности, мы просим
      вас воздержаться от пользования нашими услугами. Если вы согласны с нашим Положением о
      конфиденциальности, тогда вы можете начать пользоваться предоставляемыми нами услугами без
      ограничений.Мы можем время от времени изменять Положение о конфиденциальности, поэтому
      рекомендуем вам регулярно проверять эту страницу. Если мы внесем в Положение о
      конфиденциальности такие изменения, которые непосредственно вас затронут, мы сообщим вам о
      таких изменениях до вступления их в силу. Если Вы не согласны с этим Положением о
      конфиденциальности, мы просим вас воздержаться от пользования нашими услугами. Если вы
      согласны с нашим Положением о конфиденциальности, тогда вы можете начать пользоваться
      предоставляемыми нами услугами без ограничений.
    </p>
    <h2>Положение о cookie-файлах</h2>
    <p>
      Мы используем cookie-файлы и другие технологии отслеживания, которые мы также будем называть
      «cookie-файлами» в настоящем Документе.
    </p>
    <p>
      Cookie-файл — это небольшой фрагмент информации, который передается в браузер на вашем
      компьютере или мобильном устройстве.
    </p>
    <p>
      Файлы хранят информацию о контенте, который вы просматривали и с которым взаимодействовали,
      чтобы запомнить ваши предпочтения и проанализировать, как вы используете онлайн-сервисы.
    </p>
    <p>
      Данные файлы могут быть использованы различным образом: например, для поддержки работы сайта
      AskBee.ru, анализа трафика или в рекламных целях. Cookie-файлы браузера могут хранить
      информацию о вашем IP-адресе, о типе браузера, информацию о контенте, который вы
      просматривали. Сохраняя эти сведения, cookie-файлы могут запоминать ваши предпочтения и
      настройки, анализировать, как вы ими пользуетесь.
    </p>
    <h5 className="subTitle">Инструмент &quot;Согласие на использование файлов cookie&quot;</h5>
    <p>
      Чтобы Вы могли контролировать передаваемую информацию, мы используем &quot;Согласие на
      использование файлов cookie&quot;, благодаря которому можно просматривать как наши, так и
      сторонние файлы cookie, размещаемые на нашем сайте, и решать, принимать или отклонять эти
      файлы.
    </p>
    <h2>Термины в Положении о конфиденциальности</h2>
    <p>Все функции и Услуги принадлежат компании “AskBee and Me” и оказываются ею.</p>
    <p>
      «Турпродукт» означает различные продукты и услуги для путешествий, которые вы можете заказать,
      купить, оплатить, забронировать или комбинировать между собой на нашем сайте.
    </p>
    <p>
      «Турагент» означает поставщиков услуг по бронированию и оформлению турпродукта (транспортные
      услуги + отель) или размещения гостей в отель, поставщиков услуг страхования туристов и всех
      прочих поставщиков услуг или продуктов, которые доступны для Бронирования на платформе.
    </p>
    <p>
      «Сервис» означает онлайн-покупку, заказ, оплату (обработку оплаты) или резервирование,
      предлагаемое компанией AskBee в отношении продуктов и услуг на платформе.
    </p>
    <p>
      «Бронирование» означает заказ, покупку, оплату или резервирование турпродукта или размещения.
    </p>
    <h2>Какие персональные мы собираем?</h2>
    <p>
      Когда Вы используете наши услуги, мы собираем предоставляемую Вами информацию и данные о Вас
      (Ваше имя, контактные данные, отсутствие или наличие у Вас регистрации в каких-либо социальных
      сетях и тд).
    </p>
    <p>
      Мы также собираем некоторые данные с вашего компьютера, телефона, планшета или иного
      устройства, которое вы используете для доступа к нашему сайту (например IP-адрес, тип браузера
      и языковые настройки) Иногда мы получаем информацию о Вас от третьих сторон, наших деловых
      партнеров и прочих источников.
    </p>
    <h2>Как мы используем Вашу информацию</h2>
    <p>
      Информацию о Вас мы используем для подбора наилучшего варианта размещения для Вас и Ваших
      спутников, сократив время поиска до минимума.
    </p>
    <h2>Кто имеет доступ к Вашей информации</h2>
    <ul>
      <li>
        ТурКомпании нашей группы. Мы делимся информацией о Вас с нашей туристической компанией,
        чтобы предлагать Вам информацию о туристических продуктах и услугах, которые могут Вас
        заинтересовать. Эти компании также соблюдают требования настоящего Документа и всех
        действующих законов, регулирующих процесс рассылки рекламных материалов.
      </li>
      <li>
        Поставщики услуг. Мы передаем информацию о Вас компаниям, которые помогают нам предоставлять
        наши Услуги (например, Туроператорам, для оформления путевки и внесения Ваших данных в
        систему ), в том числе при организации операций, а также бронирования.
      </li>
      <li>
        Деловые партнеры. Мы можем передавать информацию о Вас, Ваших Устройствах своим доверенным
        деловым партнерам (Отелям, Перевозчикам).
      </li>
      <li>
        Социальные сети. Если Вы, желаете опубликовать свои фотографии в социальных сетях,используя
        наш сайт или приложение, то передаваемая Вами информация будет приниматься и обрабатываться
        в соответствии с политикой конфиденциальности этих социальных сетей, а также с настройками
        конфиденциальности, которые Вы установите в своем профиле в этих сетях.
      </li>
      <li>
        Рекламные сети. Иногда мы собираем и передаем информацию о Ваших интересах рекламным сетям.
        Некоторые из них предлагают возможность отказаться от рекламных объявлений.
      </li>
    </ul>
    <p>
      Они в свою очередь могут получать доступ к определенным данным и собирать только ту
      информацию, которая им необходима для выполнения согласованных функций.
    </p>
    <h2>Возможности выбора</h2>
    <p>
      У Вас есть возможность доступа к учетной записи, обновлению информации и ее закрытию на
      странице Мой профиль на нашем сайте.{' '}
    </p>
    <h2>Передача данных</h2>
    <p>
      При передаче информации мы используем и защищаем ее в соответствии с настоящим Документом, а
      также действующим законодательством.
    </p>
    <h2>Защита персональных данных</h2>
    <p>
      Мы предприняли необходимые административные, технические и организационные меры защиты
      персональной информации наших пользователей.
    </p>
    <h2>Хранение информации</h2>
    <p>
      Мы сохраняем копии предоставленной Вами информации в течение всего периода существования Вашей
      учетной записи или дольше, если это необходимо для целей настоящего Положения или в
      соответствии с требованиями действующего законодательства. Мы можем хранить Ваши данные в
      течение любого периода, пока это потребуется для установления, реализации или защиты законных
    </p>
    <h2>Как с нами связаться</h2>
    <p>
      Если у Вас есть запрос, касающийся конфиденциальности данных, например Вы хотите удалить Ваши
      данные или получить к ним доступ, свяжитесь с нами по адресу marketing@askbee.app
    </p>
    <p>
      По общим вопросам, касающимся конфиденциальности данных или нашего положения о
      конфиденциальности и использовании cookie-файлов, напишите нам marketing@askbee.app
    </p>
  </div>
);

export default PrivacyPolicy;
