import React from 'react';
import PropTypes from 'prop-types';
import customWithTranslation from 'helpers/language/customWithTranslation';
import useDidMountEffect from 'helpers/useDidMountEffectHook';
import DelightersList from './DelightersListComponent';

import styles from '../assets/Delighters.module.scss';

const MAX_SELECTED_ELEMENTS = 4;

const DelightersComponent = ({
  t,
  getDelighters,
  // from prop
  delighters,
  withoutNumbers,
  items,
  onChange,
  initialValues,
  componentRef
}) => {
  const [selectedDelighters, setSelectedDelighters] = React.useState(initialValues);

  React.useEffect(() => {
    if (delighters) {
      return;
    }

    getDelighters();
  }, []);

  useDidMountEffect(() => {
    onChange(selectedDelighters);
  }, [selectedDelighters.length]);

  useDidMountEffect(() => {
    setSelectedDelighters(initialValues);
  }, [initialValues]);

  const handlePreferenceChange = (event) => {
    const {
      currentTarget: { name, checked }
    } = event;

    setSelectedDelighters((prevProps) => {
      const newProps = checked ? [...prevProps, name] : prevProps.filter((el) => el !== name);

      return newProps;
    });
  };

  const handleReset = () => {
    setSelectedDelighters(initialValues);
  };

  React.useImperativeHandle(componentRef, () => ({
    handleReset
  }));

  return (
    <div>
      <DelightersList
        items={delighters ?? items}
        withoutNumbers={withoutNumbers}
        selectedItems={selectedDelighters}
        maxSelectedElements={!withoutNumbers ? MAX_SELECTED_ELEMENTS : 100}
        onChange={handlePreferenceChange}
      />
      {!withoutNumbers && (
        <div className={styles.limitDescription}>
          {t('limitDescription', { maxCount: MAX_SELECTED_ELEMENTS })}
        </div>
      )}
    </div>
  );
};

DelightersComponent.defaultProps = {
  onChange: () => {},
  initialValues: [],
  withoutNumbers: false,
  componentRef: undefined,
  delighters: undefined
};

DelightersComponent.propTypes = {
  t: PropTypes.func.isRequired,
  getDelighters: PropTypes.func.isRequired,
  withoutNumbers: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  delighters: PropTypes.array,
  initialValues: PropTypes.arrayOf(PropTypes.string),
  componentRef: PropTypes.any
};

const DelightersWithTranslation = customWithTranslation('Delighters')(DelightersComponent);

const ForwardedDelightersWithTranslation = React.forwardRef((props, ref) => (
  <DelightersWithTranslation componentRef={ref} {...props} />
));
ForwardedDelightersWithTranslation.displayName = 'ForwardedDelightersWithTranslation';

export default ForwardedDelightersWithTranslation;
