import React from 'react';
import PropTypes from 'prop-types';
import ScrollableHotelsSection from 'common/base-components/ScrollableHotelsSection';

const CompilationHotelsByNameComponent = ({ onGetHotels, compilationName, size }) => {
  const [hotels, setHotels] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getHotels = async () => {
    try {
      setIsLoading(true);

      const items = await onGetHotels(compilationName);

      setHotels(items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getHotels();
  }, []);

  return (
    <ScrollableHotelsSection
      title={compilationName}
      size={size}
      items={hotels}
      loading={isLoading}
    />
  );
};

CompilationHotelsByNameComponent.defaultProps = {
  size: undefined
};

CompilationHotelsByNameComponent.propTypes = {
  onGetHotels: PropTypes.func.isRequired,
  compilationName: PropTypes.string.isRequired,
  size: PropTypes.string
};

export default CompilationHotelsByNameComponent;
