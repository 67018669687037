import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Title';
import SpaceBetweenBlock from '../SpaceBetweenBlock';
import ScrollableHotelsList from './ScrollableHotelsList';

const ScrollableHotelsSection = ({ title, size, items, loading }) => (
  <div>
    <SpaceBetweenBlock>
      <Title>{title}</Title>
    </SpaceBetweenBlock>
    <ScrollableHotelsList loading={loading} items={items} size={size} />
  </div>
);

ScrollableHotelsSection.defaultProps = {
  title: '',
  size: 'default',
  loading: false
};

ScrollableHotelsSection.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small']),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool
};

export default ScrollableHotelsSection;
