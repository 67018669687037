import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import customWithTranslation from 'helpers/language/customWithTranslation';
import capitalize from 'helpers/capitalize';
import DelightersItem from './DelightersItemComponent';

import styles from '../assets/DelightersList.module.scss';

const DelightersListComponent = ({
  items,
  selectedItems,
  onChange,
  withoutNumbers,
  maxSelectedElements,
  keyPrefix,
  size,
  orientation,
  tBack
}) => {
  const classNameKeyByOrientation = React.useMemo(
    () => `delightersListWrapper${capitalize(orientation)}`,
    [orientation]
  );

  const getSelectedItemNumber = (preference) => {
    const selectedPreferenceIndex = selectedItems.indexOf(preference.key);

    return selectedPreferenceIndex === -1 ? undefined : selectedPreferenceIndex + 1;
  };

  const isDisableItem = (selectedItemNumber, item) =>
    item.ruName.toLowerCase() === 'coming soon' ||
    (selectedItems.length === maxSelectedElements && !selectedItemNumber);

  return (
    <div className={cn(styles.delightersListWrapper, styles[classNameKeyByOrientation])}>
      <div className={styles.delightersList}>
        {items.map((item) => {
          const selectedItemNumber = getSelectedItemNumber(item);
          const uniqKey = `${keyPrefix}-${item.key}`;

          return (
            <DelightersItem
              key={uniqKey}
              uniqKey={uniqKey}
              item={item}
              className={styles.delightersItem}
              withoutNumbers={withoutNumbers}
              selectedNumber={selectedItemNumber}
              isDisable={isDisableItem(selectedItemNumber, item)}
              onChange={onChange}
              tBack={tBack}
              size={size}
            />
          );
        })}
      </div>
    </div>
  );
};

DelightersListComponent.defaultProps = {
  maxSelectedElements: 5,
  selectedItems: [],
  onChange: () => {},
  size: 'medium',
  orientation: 'default',
  keyPrefix: 'preferences'
};

DelightersListComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  tBack: PropTypes.func.isRequired,
  keyPrefix: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxSelectedElements: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  withoutNumbers: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['default', 'horizontal'])
};

export default customWithTranslation('DelightersList')(DelightersListComponent);
