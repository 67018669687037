import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './assets/VerticalScrollableBlock.module.scss';

const VerticalScrollableBlock = ({ className, children, ...divProps }) => (
  <div className={cn(styles.wrapper, className)} {...divProps}>
    {children}
  </div>
);

VerticalScrollableBlock.defaultProps = {
  className: ''
};

VerticalScrollableBlock.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any.isRequired
};

export default VerticalScrollableBlock;
