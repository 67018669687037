import React from 'react';
import PropTypes from 'prop-types';
import FixedFiltersButton from 'common/base-components/FixedFiltersButton';
import FilterIcon from './FilterIcon';
import FiltersByFactsDialog from './FiltersByFactsDialog';

import styles from './assets/FiltersByFacts.module.scss';

const FiltersByFacts = ({ initialValues, ...filtersProps }) => {
  const filtersByFactsDialogRef = React.useRef(null);

  const handleShowClick = React.useCallback(() => {
    filtersByFactsDialogRef.current.handleChangeVisibility(true);
  }, []);

  return (
    <div>
      <FixedFiltersButton onClick={handleShowClick} className={styles.showButton}>
        <FilterIcon />
        Фильтры
        {initialValues.length > 0 && (
          <>
            <span className={styles.dotSeparator} /> {initialValues.length}
          </>
        )}
      </FixedFiltersButton>
      <FiltersByFactsDialog
        ref={filtersByFactsDialogRef}
        initialValues={initialValues}
        {...filtersProps}
      />
    </div>
  );
};

FiltersByFacts.defaultProps = {
  initialValues: []
};

FiltersByFacts.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.string)
};

export default FiltersByFacts;
