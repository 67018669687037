import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// import testAva from '../assets/test-ava.png';

import styles from '../assets/UsetProfile.module.scss';

const UsetProfileComponent = ({ size, classes, profile }) => (
  <div className={cn(styles.root, classes.root)}>
    <div
      className={cn(styles.userAvatar, classes.userAvatar)}
      style={{ backgroundImage: profile.picture ? `url(${profile.picture})` : 'none' }}
    />
    {size !== 'small' && (
      <div className={styles.info}>
        <h5>{profile.name}</h5>
        <p>{profile.email}</p>
      </div>
    )}
  </div>
);

UsetProfileComponent.defaultProps = {
  size: 'default',
  classes: {
    root: undefined,
    userAvatar: undefined
  },
  profile: undefined
};

UsetProfileComponent.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
  classes: PropTypes.shape({
    root: PropTypes.string,
    userAvatar: PropTypes.string
  }),
  profile: PropTypes.shape({
    active: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
    invitedCode: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string
  })
};

export default UsetProfileComponent;
