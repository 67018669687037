import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LoadingProgress from 'common/base-components/LoadingProgress';
import ComboBox from 'common/compound-components/ComboBox/containers/ComboBoxContainer';

import styles from '../assets/UploadFormComponent.module.scss';

const DelightersCheckboxesList = ({ onChange, cmpRef }) => {
  const [items, setItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState({});

  const getDelighters = async () => {
    try {
      const { data } = await axios.get('/delights');

      setItems(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getDelighters();
  }, []);

  const handleChange = (event) => {
    const newValues = { ...selectedItems, [event.target.name]: event.target.checked };

    setSelectedItems(newValues);
    onChange(Object.keys(newValues).map((key) => key));
  };

  const handleReset = () => {
    setSelectedItems({});
  };

  React.useImperativeHandle(cmpRef, () => ({
    handleReset
  }));

  return (
    <div className={styles}>
      {items.map((item) => (
        <FormControlLabel
          key={item.key}
          control={
            <Checkbox name={item.key} onChange={handleChange} checked={!!selectedItems[item.key]} />
          }
          label={item.ruName}
        />
      ))}
    </div>
  );
};

DelightersCheckboxesList.propTypes = {
  onChange: PropTypes.func.isRequired,
  cmpRef: PropTypes.any.isRequired
};

const UploadImageInputWithPreview = ({ onChange, cmpRef }) => {
  const fileInputRef = React.useRef(null);
  const [previewSrc, setPreviewSrc] = React.useState(null);

  const handleChange = (event) => {
    const { files } = event.target;

    onChange(files[0]);

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setPreviewSrc(e.target.result);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleReset = () => {
    fileInputRef.current.value = null;
    onChange(null);
    setPreviewSrc(null);
  };

  React.useImperativeHandle(cmpRef, () => ({
    handleReset
  }));

  return (
    <div>
      <div>
        <Button variant="contained" component="label" startIcon={<ImageIcon />}>
          Upload image
          <input ref={fileInputRef} type="file" onChange={handleChange} hidden />
        </Button>
      </div>

      {previewSrc && (
        <div style={{ marginTop: '10px' }}>
          <div>
            <Button onClick={handleReset}>Reset image</Button>
          </div>
          <img src={previewSrc} alt="preview" style={{ width: '100px' }} />
        </div>
      )}
    </div>
  );
};

UploadImageInputWithPreview.propTypes = {
  onChange: PropTypes.func.isRequired,
  cmpRef: PropTypes.any.isRequired
};

const UploadFormComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const comboBoxRef = React.useRef(null);
  const delightersRef = React.useRef(null);
  const uploadImageRef = React.useRef(null);

  const [state, setState] = React.useState({
    hotelName: '',
    comment: '',
    selectedDelighters: [],
    file: null
  });

  const onSelectHotelName = (name) => {
    setState((prevState) => ({ ...prevState, hotelName: name }));
  };

  const onImageChange = (file) => {
    setState((prevState) => ({ ...prevState, file }));
  };

  const onDelightersChange = (values) => {
    setState((prevState) => ({ ...prevState, selectedDelighters: values }));
  };

  const handleCommentChange = (event) => {
    setState((prevState) => ({ ...prevState, comment: event.target.value }));
  };

  const handleReset = () => {
    comboBoxRef.current.handleReset();
    delightersRef.current.handleReset();
    uploadImageRef.current.handleReset();
    setState({ hotelName: '', comment: '', selectedDelighters: [], file: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { hotelName, comment, file, selectedDelighters } = state;

    if (!hotelName || selectedDelighters.length === 0 || !file) {
      return enqueueSnackbar('Fill in all required fields', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }

    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append('hotelName', hotelName);
      formData.append('comment', comment);
      formData.append('file', file, file?.name);
      formData.append('delight', JSON.stringify(selectedDelighters));

      await axios.post('/import-hotel-image', formData);

      setIsLoading(false);
      handleReset();

      return enqueueSnackbar('Successful', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    } catch (error) {
      setIsLoading(false);

      const { message } = error.response.data;

      if (message) {
        return enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      }

      throw new Error(error);
    }
  };

  return (
    <div className={styles.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading && <LoadingProgress />}
          <form onSubmit={handleSubmit}>
            <ComboBox
              ref={comboBoxRef}
              apiPath="/hotels/names"
              onSelectValue={onSelectHotelName}
              placeholder="Наименование отеля"
            />
            <TextField
              label="Comment"
              multiline
              rows={2}
              variant="filled"
              style={{ width: '100%', margin: '10px 0' }}
              value={state.comment}
              onChange={handleCommentChange}
            />
            <DelightersCheckboxesList cmpRef={delightersRef} onChange={onDelightersChange} />
            <UploadImageInputWithPreview cmpRef={uploadImageRef} onChange={onImageChange} />
            <div className="actions-block" style={{ marginTop: '10px' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
              >
                Submit
              </Button>
              <Button onClick={handleReset} variant="contained">
                Reset
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

UploadFormComponent.propTypes = {};

export default UploadFormComponent;
