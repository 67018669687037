import groupBy from './groupBy';

export default (data = []) => {
  const groupedCompilation = groupBy(data, 'compilationName');

  return Object.keys(groupedCompilation).reduce((acc, current) => {
    acc[current] = groupedCompilation[current].map((item) => item.hotel);

    return acc;
  }, {});
};
