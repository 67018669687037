import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import capitalize from 'helpers/capitalize';
import getImageSrc from 'helpers/getImageSrc';
import SvgInline from 'common/base-components/SvgInline';

import styles from '../assets/DelightersItem.module.scss';

const DelightersItemComponent = ({
  item,
  selectedNumber,
  withoutNumbers,
  uniqKey,
  tBack,
  isDisable,
  onChange,
  className,
  size
}) => {
  const { key } = item;

  const classNameKeyBySize = React.useMemo(() => `delightersItemWrapper${capitalize(size)}`, [
    size
  ]);

  return (
    <div className={styles.delightersItem}>
      <label
        htmlFor={uniqKey}
        className={cn(
          styles.delightersItemWrapper,
          styles[classNameKeyBySize],
          className,
          selectedNumber && styles.delightersItemWrapperActive,
          isDisable && styles.delightersItemWrapperDisabled
        )}
      >
        <span className={styles.iconBlock}>
          <SvgInline url={getImageSrc(item.image)} />
          {!withoutNumbers && (
            <span className={styles.selectedNumberWrapper}>
              <span className={styles.selectedNumber}>{selectedNumber}</span>
            </span>
          )}
        </span>
        <span>{tBack(item, 'name')}</span>
        <input
          type="checkbox"
          id={uniqKey}
          defaultChecked={Boolean(selectedNumber)}
          name={key}
          disabled={isDisable}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

DelightersItemComponent.defaultProps = {
  selectedNumber: undefined,
  isDisable: false,
  onChange: () => {},
  className: undefined,
  size: 'medium'
};

DelightersItemComponent.propTypes = {
  item: PropTypes.object.isRequired,
  tBack: PropTypes.func.isRequired,
  uniqKey: PropTypes.string.isRequired,
  withoutNumbers: PropTypes.bool.isRequired,
  selectedNumber: PropTypes.number,
  isDisable: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small'])
};

export default DelightersItemComponent;
