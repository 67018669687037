import React from 'react';
import PropTypes from 'prop-types';
import ProfileWrapperContext from './ProfileWrapperContext';
// import useUserProfile from '../hook/useUserProfile';

const ProfileWrapper = ({ children }) => {
  const [profile, setProfile] = React.useState(JSON.parse(localStorage.getItem('profile')) ?? {});

  return (
    <ProfileWrapperContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileWrapperContext.Provider>
  );
};

ProfileWrapper.propTypes = {
  children: PropTypes.any.isRequired
};

export default ProfileWrapper;
