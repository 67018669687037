import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import TouchSimulate from 'touch-simulate';

import styles from './assets/SwipeableDrawerPanel.module.scss';

const SwipeableDrawerPanel = ({
  overflowHeight,
  onSwitching,
  onOverlayClick,
  children,
  componentRef
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const bottomSheetRef = useRef(null);
  const swipeRef = useRef(null);

  const handleChangeVisibility = React.useCallback(
    (isOpenDrawer) => {
      const node = swipeRef.current.rootNode;

      const touch = new TouchSimulate(node, {
        point: false,
        speed: 1200
      });

      return isOpenDrawer ? touch.start().moveUp(200) : touch.start().moveDown(200);
    },
    [swipeRef.current]
  );

  const handleOverlayClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    handleChangeVisibility(false);
    onOverlayClick?.();
  };

  const attachOverlayClickHandling = () => {
    const { _reactInternals: reactInternals } = bottomSheetRef.current;
    const overlay = reactInternals.child.stateNode.firstChild;

    overlay.addEventListener('click', handleOverlayClick);
  };

  React.useEffect(() => {
    // need to stop the propagation when we clicked on the overlay and attach our method - handleChangeVisibility (simulate touch)
    attachOverlayClickHandling();
  }, []);

  React.useImperativeHandle(componentRef, () => ({
    isOpen,
    handleChangeVisibility,
    swipeRef
  }));

  return (
    <SwipeableBottomSheet
      ref={bottomSheetRef}
      overflowHeight={overflowHeight}
      marginTop={20}
      open={isOpen}
      onChange={setIsOpen}
      style={{ zIndex: 999, maxWidth: '600px', margin: 'auto' }}
      containerStyle={{ borderRadius: '8px 8px 0 0' }}
      bodyStyle={{ borderRadius: '8px 8px 0 0' }}
      swipeableViewsProps={{
        ref: swipeRef,
        containerStyle: { borderRadius: '8px 8px 0 0' },
        onSwitching
      }}
    >
      <div className={styles.expandBlock}>
        <div className={styles.expandButton} />
      </div>
      <div className={styles.contentWrapper}>{children}</div>
    </SwipeableBottomSheet>
  );
};

SwipeableDrawerPanel.defaultProps = {
  overflowHeight: 40,
  onSwitching: () => {},
  onOverlayClick: undefined,
  componentRef: undefined
};

SwipeableDrawerPanel.propTypes = {
  overflowHeight: PropTypes.number,
  onSwitching: PropTypes.func,
  onOverlayClick: PropTypes.func,
  children: PropTypes.any.isRequired,
  componentRef: PropTypes.any
};

export default React.forwardRef((props, ref) => (
  <SwipeableDrawerPanel componentRef={ref} {...props} />
));
