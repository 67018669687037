import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ProgressCircle from 'common/base-components/ProgressCircle';
import getPercentage from './helpers/getPercentage';

import styles from './assets/HotelCardDelighterItem.module.scss';

const HotelCardDelighterItem = ({ tBack, item, onClick, isSelectable, isActive, isSelected }) => {
  const percent = React.useMemo(() => getPercentage(item.value), []);

  const handleClick = () => {
    if (!isSelectable) {
      return;
    }

    onClick(item);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        styles.wrapper,
        isActive && isSelectable && styles.activeItem,
        isSelected && styles.selectedItem
      )}
      onClick={() => handleClick()}
      onKeyDown={handleClick}
    >
      <ProgressCircle percent={percent} icon={item.image} />
      <div className={styles.title}>{tBack(item, 'name')}</div>
    </div>
  );
};

HotelCardDelighterItem.defaultProps = {
  isSelected: false
};

HotelCardDelighterItem.propTypes = {
  tBack: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool
};

export default HotelCardDelighterItem;
