import { connect } from 'react-redux';
import axios from 'axios';
import WeatherWidgetComponent from '../components/WeatherWidgetComponent';

const getWeathers = async (country, location) => {
  try {
    const { data } = await axios.get(`/weathers?country=${country}&location=${location}`);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const mapDispatchToProps = () => ({
  getWeathers: (country, location) => getWeathers(country, location)
});

export default connect(null, mapDispatchToProps)(WeatherWidgetComponent);
