import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import customWithTranslation from 'helpers/language/customWithTranslation';
import CardActionArea from '@material-ui/core/CardActionArea';
import LikeButton from 'common/compound-components/LikeButton/containers/LikeButtonContainers';
import getImageSrc from 'helpers/getImageSrc';

import styles from './assets/HotelCard.module.scss';

const HotelCard = ({ tBack, item }) => (
  <div className={styles.wrapper}>
    <CardActionArea className={styles.cardActionArea}>
      <Link to={`/details/${item.id}`} target="_blank">
        <section className={styles.gradientOverlay} />
        <section
          className={styles.imageBg}
          style={{ backgroundImage: `url(${getImageSrc(item.images?.[0]?.name)})` }}
        />
        <footer>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.location}>
            <LocationOnOutlinedIcon /> {tBack(item, 'locationName')}
          </div>
        </footer>
      </Link>
    </CardActionArea>
    <div className={styles.likeButtonWrapper}>
      <LikeButton hotelId={item.id} />
    </div>
  </div>
);

HotelCard.propTypes = {
  tBack: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default customWithTranslation()(HotelCard);
