import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import SwipeableDrawerPanel from 'common/base-components/SwipeableDrawerPanel';
import Delighters from 'common/compound-components/Delighters/containers/DelightersContainer';
import Button from 'common/base-components/Button';
import ComboBox from 'common/compound-components/ComboBox/containers/ComboBoxContainer';
import Title from 'common/base-components/Title';
import { setSearchParamsPanelVisibility } from '../../../store/settings/actions';

import styles from './assets/SwipeableSearchParamsPanel.module.scss';

const SwipeableSearchParamsPanel = ({ title, initialValues, parsedSearchParams, history }) => {
  const swipeableDrawerPanelRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const findHotelsButtonWrapperRef = useRef(null);
  const delightersRef = useRef(null);
  const settings = useRef(initialValues);
  const swipeableDrawerTimer = useRef(null);
  const dispatch = useDispatch();
  const isSearchBarVisible = useSelector((state) => state.settings.isSearchBarVisible);

  useEffect(
    () => () => {
      document.body.classList.remove('disabled-scroll');
      clearTimeout(swipeableDrawerTimer.current);
    },
    []
  );

  const handleDelightersChange = (selectedDelighters) => {
    settings.current.delighters = selectedDelighters;
  };

  const handleLocationSelected = (locationName) => {
    settings.current.locationName = locationName;
  };

  const handleSwitching = (index) => {
    contentWrapperRef.current.style.opacity = index;
    findHotelsButtonWrapperRef.current.style.opacity = 1 - index;

    if (index > 0.3) {
      findHotelsButtonWrapperRef.current.style.display = 'none';
      document.body.classList.add('disabled-scroll');
    } else {
      findHotelsButtonWrapperRef.current.style.display = 'block';
      document.body.classList.remove('disabled-scroll');
    }
  };

  const handleOpenDrawerPanel = () => {
    swipeableDrawerPanelRef.current.handleChangeVisibility(true);
  };

  const handleCloseDrawerPanel = () => {
    swipeableDrawerPanelRef.current.handleChangeVisibility(false);
    delightersRef.current.handleReset();
  };

  useEffect(() => {
    if (isSearchBarVisible) {
      handleOpenDrawerPanel();

      dispatch(setSearchParamsPanelVisibility(false));
    }
  }, [isSearchBarVisible]);

  const handleApply = () => {
    const { delighters, locationName } = settings.current;
    const { likedHotelsIds } = initialValues;
    const querySettings = { ...parsedSearchParams, delighters, locationName, likedHotelsIds };
    const searchString = qs.stringify(querySettings, { arrayFormat: 'bracket' });

    swipeableDrawerTimer.current = setTimeout(() => {
      swipeableDrawerPanelRef.current.handleChangeVisibility(false);
    }, 0);

    history.push({
      pathname: '/search-results',
      search: searchString
    });
    window.scrollTo(0, 0);
  };

  const { delighters, locationName } = settings.current;

  return (
    <SwipeableDrawerPanel
      ref={swipeableDrawerPanelRef}
      overflowHeight={80}
      onSwitching={handleSwitching}
      onOverlayClick={() => delightersRef.current.handleReset()}
    >
      <div ref={findHotelsButtonWrapperRef}>
        <Button type="default" onClick={handleOpenDrawerPanel} fullWidth>
          {title}
        </Button>
      </div>
      <div className={styles.contentWrapper} ref={contentWrapperRef}>
        <ComboBox
          apiPath="/hotels/locations"
          onSelectValue={handleLocationSelected}
          searchInputClassName={styles.searchInput}
          initialValue={locationName}
          placeholder="Куда Вы едете (страна, регион, отель)?"
        />
        <Title className={styles.title} variant="h6">
          Выберите что важно на отдыхе
        </Title>
        <div className={styles.delightersWrapper}>
          <Delighters
            ref={delightersRef}
            initialValues={delighters}
            onChange={handleDelightersChange}
          />
        </div>
        <div className={styles.controlButtons}>
          <Button onClick={handleApply} fullWidth>
            Применить
          </Button>
          <Button type="apply" variant="text" onClick={handleCloseDrawerPanel} fullWidth>
            Отменить
          </Button>
        </div>
      </div>
    </SwipeableDrawerPanel>
  );
};

SwipeableSearchParamsPanel.defaultProps = {
  initialValues: {
    delighters: [],
    search: ''
  },
  title: 'Найти отель',
  parsedSearchParams: {}
};

SwipeableSearchParamsPanel.propTypes = {
  initialValues: PropTypes.object,
  title: PropTypes.string,
  parsedSearchParams: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default SwipeableSearchParamsPanel;
