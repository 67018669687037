import React from 'react';
import PropTypes from 'prop-types';
import customWithTranslation from 'helpers/language/customWithTranslation';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import HotelCardDelighterItem from './HotelCardDelighterItem';

import styles from './assets/HotelCardDelighters.module.scss';

const HotelCardSubDelighters = React.lazy(() => import('./HotelCardSubDelighters'));

const HotelCardDelighters = ({
  delighters,
  selectedDelightersKeys,
  isSelectable,
  tBack,
  className
}) => {
  const [activeDelighter, setActiveDelighter] = React.useState(null);

  const handleDelightItemClick = React.useCallback((item) => {
    setActiveDelighter(item);
  }, []);

  const { selected: selectedDelighters, additional: additionalDelighters } = React.useMemo(() => {
    if (delighters.length === 0) {
      return { selected: [], additional: [] };
    }

    if (!selectedDelightersKeys) {
      return { selected: [], additional: delighters };
    }

    const selected = delighters.filter((delighter) =>
      selectedDelightersKeys.some((item) => item === delighter.key)
    ); // eslint-disable-line
    const additional = delighters.filter(
      (delighter) => !selectedDelightersKeys.some((item) => item === delighter.key)
    );

    return {
      selected,
      additional
    };
  }, [selectedDelightersKeys, delighters]);

  React.useEffect(() => {
    if (selectedDelighters.length === 0 && additionalDelighters.length === 0) {
      return;
    }

    setActiveDelighter(selectedDelighters[0] ?? additionalDelighters[0]);
  }, [selectedDelighters, additionalDelighters]);

  return (
    <div className={className}>
      <VerticalScrollableBlock className={styles.wrapper}>
        <>
          {selectedDelighters.map((delighter) => (
            <HotelCardDelighterItem
              key={delighter.key}
              item={delighter}
              onClick={handleDelightItemClick}
              isActive={activeDelighter?.key === delighter.key}
              isSelectable={isSelectable}
              tBack={tBack}
            />
          ))}
          {additionalDelighters.map((delighter) => (
            <HotelCardDelighterItem
              key={delighter.key}
              item={delighter}
              onClick={handleDelightItemClick}
              isActive={activeDelighter?.key === delighter.key}
              isSelectable={isSelectable}
              tBack={tBack}
            />
          ))}
        </>
      </VerticalScrollableBlock>
      {isSelectable && delighters.length > 0 && activeDelighter && (
        <React.Suspense fallback={null}>
          <HotelCardSubDelighters selectedDelight={activeDelighter} tBack={tBack} />
        </React.Suspense>
      )}
    </div>
  );
};

HotelCardDelighters.defaultProps = {
  isSelectable: false,
  className: '',
  selectedDelightersKeys: undefined
};

HotelCardDelighters.propTypes = {
  delighters: PropTypes.array.isRequired,
  selectedDelightersKeys: PropTypes.arrayOf(PropTypes.string),
  tBack: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  className: PropTypes.string
};

export default customWithTranslation()(HotelCardDelighters);
