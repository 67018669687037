export default (data, key, iteratee, checkAccKey) =>
  data.reduce((acc, current) => {
    if (current[key] === undefined) return acc;

    const value = iteratee ? iteratee(current) : current;
    const checkedAccKey = checkAccKey ? checkAccKey(current[key]) : current[key];

    acc[checkedAccKey] = (acc[checkedAccKey] || []).concat(value);

    return acc;
  }, {});
