import reducerRegistry from 'reducerRegistry';
import {
  LIKED_HOTELS_LIKE_HOTEL,
  LIKED_HOTELS_UNLIKE_HOTEL,
  LIKED_HOTELS_GET_LIKED_HOTELS_BEGIN,
  LIKED_HOTELS_GET_LIKED_HOTELS_SUCCESS,
  LIKED_HOTELS_GET_LIKED_HOTELS_ERROR
} from './actionTypes';

const initialState = {
  itemsIds: JSON.parse(localStorage.getItem('likedHotelsIds')) ?? [],
  items: []
};

const likedHotelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIKED_HOTELS_LIKE_HOTEL:
      return {
        ...state,
        itemsIds: action.payload
      };
    case LIKED_HOTELS_UNLIKE_HOTEL:
      return {
        ...state,
        itemsIds: action.payload
      };
    case LIKED_HOTELS_GET_LIKED_HOTELS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case LIKED_HOTELS_GET_LIKED_HOTELS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false
      };
    case LIKED_HOTELS_GET_LIKED_HOTELS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

reducerRegistry.register('likedHotels', likedHotelsReducer);
