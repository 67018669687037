import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/base-components/Button';

import styles from './assets/LoadMoreButton.module.scss';

const LoadMoreButton = ({ onClick, disabled }) => (
  <div className={styles.loadMoreBlock}>
    <Button
      variant="outlined"
      className={styles.loadMoreButton}
      onClick={onClick}
      onKeyPress={onClick}
      disabled={disabled}
    >
      Еще
    </Button>
  </div>
);

LoadMoreButton.defaultProps = {
  disabled: false
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default LoadMoreButton;
