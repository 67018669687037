import reducerRegistry from 'reducerRegistry';
import {
  SEARCH_RESULTS_GET_HOTELS_BEGIN,
  SEARCH_RESULTS_GET_HOTELS_SUCCESS,
  SEARCH_RESULTS_GET_HOTELS_ERROR,
  SEARCH_RESULTS_RESET_STATE
} from './actionTypes';

const initialState = {
  items: [],
  loading: false,
  hasMore: false,
  error: ''
};

const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RESULTS_GET_HOTELS_BEGIN:
      return {
        ...state,
        hasMore: false,
        loading: true
      };
    case SEARCH_RESULTS_GET_HOTELS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        hasMore: action.payload.hasMore,
        loading: false
      };
    case SEARCH_RESULTS_GET_HOTELS_ERROR:
      return {
        ...state,
        loading: false,
        hasMore: false,
        error: action.payload
      };
    case SEARCH_RESULTS_RESET_STATE:
      return {
        items: [],
        loading: false,
        hasMore: false,
        error: ''
      };
    default:
      return state;
  }
};

reducerRegistry.register('searchResults', searchResultsReducer);
