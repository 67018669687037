import React from 'react';
import PropTypes from 'prop-types';
import customWithTranslation from 'helpers/language/customWithTranslation';
import cn from 'classnames';

import styles from './assets/FactsList.module.scss';

const FactsList = ({ tBack, items, selectedItems, onChange }) => (
  <div className={styles.wrapper}>
    {items.map((item) => {
      const isSelected = selectedItems[item.key];

      return (
        <label
          key={item.key}
          htmlFor={item.key}
          className={cn(styles.item, isSelected && styles.selectedItem)}
        >
          <span>{tBack(item, 'name')}</span>
          <input
            type="checkbox"
            id={item.key}
            name={item.key}
            onChange={onChange}
            defaultChecked={isSelected}
          />
        </label>
      );
    })}
  </div>
);

FactsList.propTypes = {
  tBack: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default customWithTranslation()(FactsList);
