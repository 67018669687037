import React from 'react';
import Header from 'common/compound-components/Header/components/HeaderComponent';
import DomainLink from './DomainLink';

const TermsOfUse = () => (
  <div className="text-content">
    <Header />
    <h2 className="first">Правила и условия использования веб-сайта</h2>
    <p>
      Пользуясь сервисами, предлагаемыми <DomainLink />, Вы принимаете условия данного Соглашения об
      использовании ресурса, вне зависимости от того, являетесь ли Вы «Зарегистрированным
      пользователем» (что подразумевает регистрацию на интернет-ресурсе <DomainLink />) или нет, а
      также, вне зависимости от цели и субъекта использования.
    </p>
    <h2>Соглашение об условиях использования ресурса</h2>
    <h5 className="subTitle">Термины и определения</h5>
    <ul>
      <li>
        Соглашение – Соглашение об условиях пользования ресурса <DomainLink />
      </li>
      <li>
        Администратор – администраторы / модераторы / правообладатели, а равно иные законные
        владельцы ресурса <DomainLink />.
      </li>
      <li>
        Ресурс (Сервис) – интернет сайт <DomainLink />.
      </li>
      <li>
        Материалы – информация, размещенная на ресурсе: тексты, статьи, фотоизображения,
        видеоизображения.
      </li>
      <li>
        Пользователь – это конкретное лицо, либо организация, которое посещает интернет-ресурс
        <DomainLink />
      </li>
    </ul>
    <h5 className="subTitle">Общие положения</h5>
    <p>
      Необходимым условием использования сервиса <DomainLink /> является согласие Пользователя
      действовать в полном соответствии со всеми правовыми нормами РФ, нормами международного права,
      а также в соответствии с данным Соглашением.
    </p>
    <p>
      Администраторы сайта могут менять данное Соглашение в любое время. Любые изменения данного
      Соглашения вступают в силу с момента их публикации на сайте <DomainLink />. Продолжая
      использовать сервис <DomainLink /> после публикации изменений, Вы соглашаетесь действовать в
      соответствии с условиями, указанными в модифицированном Соглашении.
    </p>
    <p>
      Посещение и использование нашего сайта означает то, что Пользователь принимает все условия
      настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений. Использование
      ресурса на иных условиях не допускается.
    </p>
    <p>
      Соглашение считается заключенным с конкретным Пользователем с момента посещения ресурса этим
      Пользователем, даже несмотря на отсутствие регистрации Пользователя на ресурсе.
    </p>
    <h5 className="subTitle">Описание ресурса</h5>
    <p>
      <DomainLink /> является инновационной системой поиска туров. Системы поиска предоставляет
      возможность самостоятельно подобрать тур, ознакомиться с предложениями Туроператоров, изучить
      информацию об отелях, авиабилетах и сопутствующих туристических услугах. С помощью данного
      ресурса возможен поиск туров по стране, курорту, датам вылета, отелю, а также по большому
      числу дополнительных параметров. На ресурсе размещена информация о специальных акциях, новости
      отелей и др.
    </p>
    <h5 className="subTitle">Исключительное право. Ограничения использования ресурса</h5>
    <p>
      Ресурс представляет собой информационную базу данных. Собственнику сайта принадлежат авторские
      права на осуществленные ими подбор и расположение материалов (составительство). Материалы
      сайта могут содержать торговые марки и торговые имена (названия). Все права защищены.
    </p>
    <p>
      Дизайн, структура Сайта, изображение, графика и иные элементы, являющиеся объектом охраны по
      законодательству РФ, не могут воспроизводиться полностью или частично для создания новых
      информационных объектов, за исключением случаев договорных или партнерских отношений с
      Собственниками ресурса, при этом условия воспроизведения оговариваются в каждом случае
      индивидуально.
    </p>
    <p>
      Исключительное право изготовителя базы данных признается и действует независимо от наличия и
      действия авторских и иных исключительных прав других лиц на составляющие базу данных
      материалы.
    </p>
    <p>
      Некоторые части данного ресурса могут быть защищены паролем и могут требовать регистрации
      пользователя. После процесса регистрации на нашем сайте, Пользователю на безвозмездной основе,
      если иное не оговорено отдельно, предоставляются учетная запись и пароль, позволяющие получать
      доступ ко всем услугам и сервисам <DomainLink />. Пользователь обязуется обеспечивать
      конфиденциальность пароля, и несет полную ответственность за любой ущерб и любые
      обязательства, ставшие последствием неспособности обеспечивать конфиденциальность пароля.
    </p>
    <p>Пользователь обязуется:</p>
    <ul>
      <li>
        Не пытаться получить доступ к исходному коду, не распространять и не создавать какие-либо
        производные работы, основанные на использовании авторских материалов Ресурса или любой из
        его частей.
      </li>
      <li>
        Не входить на Ресурс какими-либо путями, отличными от предоставленного <DomainLink />
        интерфейса. Любое воспроизведение или распространение данного программного обеспечения
        строго запрещено.
      </li>
      <li>
        Не использовать никакие из торговых марок, логотипов и торговых названий с ресурса, также
        как и любую другую авторскую информацию, включая графические изображения, а также дизайн
        любой страницы или любой формы, содержащейся на странице Сайта без получения
        предварительного письменного согласия Администратора сайта.
      </li>
    </ul>
    <p>
      Администратор ресурса может по своему усмотрению и без предварительного уведомления
      запретить/ограничить Пользователю пользование ресурсом.{' '}
    </p>
    <p>
      Если обратное не указано на сайте, данное Соглашение разрешает Пользователю просматривать,
      копировать и распечатывать Материалы, в соответствии со следующими условиями:
    </p>
    <ul>
      <li>
        Любая копия Материалов или отдельной их части должна содержать ссылку на страницу ресурса
        <DomainLink /> , содержащую скопированную информацию;
      </li>
      <li>
        Пользователю дается ограниченное, неэксклюзивное право создавать гипертекстовые ссылки на
        главную и внутренние страницы ресурса, с условием того, что такая ссылка не ведет к ложному,
        уничижительному, обманному восприятию сервиса <DomainLink />.
      </li>
    </ul>
    <p>
      При этом, <DomainLink /> оставляет за собой право отменить вышеуказанные разрешения в любое
      время, без объяснения причин, вследствие чего любое использование Материалов должно быть
      немедленно прекращено по соответствующему уведомлению Администратора.
    </p>
    <h5 className="subTitle">Ограничение ответственности правообладателя</h5>
    <p>
      Администраторы сайта прилагают все необходимые усилия по обеспечению корректности всей
      информации, размещенной на Сайте. Вместе с тем не гарантируют абсолютную точность, полноту или
      достоверность информации, содержащейся на Сайте, не отвечают за неточности, возможные ошибки
      или другие недостатки в размещаемой информации.
    </p>
    <p>
      Администраторы <DomainLink /> и/или работающие с ним третьи лица могут вносить изменения в
      информацию на данном сайте в любое время.
    </p>
    <p>
      <DomainLink /> не несет ответственность за действия, ошибки, обещания, гарантии своих
      партнеров или третьих лиц, предоставляющих информацию для размещения на ресурсе, а также за
      нарушения или несоблюдения ими договоров, равно как и за любой материальный, моральный прямой
      или косвенный ущерб, или любые другие потери, возникающие вследствие вышеуказанного.
    </p>
    <p>
      Администраторы сайта не могут нести ответственность за любой прямой, косвенный убыток,
      связанный с использованием данного сайта, или с задержкой или невозможностью его
      использования, а также за любую информацию, продукты и услуги, приобретенные посредством
      данного сайта, или другим способом полученные с его помощью.
    </p>
    <p>
      Администраторы сайта не несут ответственности за временные технические сбои и перерывы в
      предоставлении услуг, за временные сбои и перерывы в работе линий связи, иные аналогичные
      сбои, а также за неполадки компьютера, с которого Пользователь осуществляет выход в Интернет.
    </p>
    <h5 className="subTitle">Разрешение споров и применяемая правовая норма</h5>
    <p>
      При обнаружении фактов нарушения условий настоящего Соглашения Администратор отправляет
      «нарушителю» досудебное уведомление с требованием устранить выявленные нарушения в
      установленный срок. При неисполнении указанных требований защита нарушенных прав и взыскание
      причиненных убытков производится в судебном порядке по месту регистрации правообладателя Сайта
      <DomainLink />
    </p>
    <p>
      Любые судебные процессы по данному Соглашению будут проводиться в Российской Федерации в г.
      Москве, в соответствии с подсудностью судов судебной системы в РФ и условиями настоящего
      Соглашения.
    </p>
    <h5 className="subTitle">Заключительные положения</h5>
    <p>
      Администратор ресурса вправе осуществлять рассылку Пользователю текстовой и графической
      информации, в том числе и рекламного характера, с помощью писем по электронной почте. В случае
      несогласия Пользователя с указанным в настоящем пункте правилом, Пользователь может отказаться
      от получения рассылки перейдя по соответствующей ссылке, имеющейся в каждом письме, и/или
      посредством отправки на адрес электронной почты Компании/при помощи формы обратной связи
      соответствующего сообщения.
    </p>
    <p>
      Если Вы не согласны с Условиями пользования, или какой-либо их частью, пожалуйста,
      воздержитесь от использования ресурса <DomainLink />
    </p>
  </div>
);

export default TermsOfUse;
