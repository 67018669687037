import detectMobileBrowser from 'helpers/detectMobileBrowser';
import {
  SETTINGS_CHANGE_LANGUAGE,
  SETTINGS_SET_SEARCH_PARAMS_PANEL_VISIBILITY,
  SETTINGS_SET_IS_ON_BOARD_PAGE_WAS_APPLIED
} from './actionTypes';

const initialState = {
  isMobileDevice: detectMobileBrowser(),
  language: localStorage.getItem('language') ?? 'ru',
  isSearchBarVisible: false,
  isOnBoardPageWasApplied: Boolean(localStorage.getItem('isOnBoardPageWasApplied')) ?? false
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case SETTINGS_SET_SEARCH_PARAMS_PANEL_VISIBILITY:
      return {
        ...state,
        isSearchBarVisible: action.payload
      };
    case SETTINGS_SET_IS_ON_BOARD_PAGE_WAS_APPLIED:
      return {
        ...state,
        isOnBoardPageWasApplied: action.payload
      };
    default:
      return state;
  }
};

export default settingsReducer;
