import {
  SETTINGS_CHANGE_LANGUAGE,
  SETTINGS_SET_SEARCH_PARAMS_PANEL_VISIBILITY,
  SETTINGS_SET_IS_ON_BOARD_PAGE_WAS_APPLIED
} from './actionTypes';

export const changeLanguage = (payload) => ({
  type: SETTINGS_CHANGE_LANGUAGE,
  payload
});

export const setSearchParamsPanelVisibility = (payload) => ({
  type: SETTINGS_SET_SEARCH_PARAMS_PANEL_VISIBILITY,
  payload
});

export const setIsOnBoardPageWasApplied = (payload) => {
  localStorage.setItem('isOnBoardPageWasApplied', payload);

  return {
    type: SETTINGS_SET_IS_ON_BOARD_PAGE_WAS_APPLIED,
    payload
  };
};
