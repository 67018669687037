import { connect } from 'react-redux';
import HomeComponent from '../components/HomeComponent';
import '../index';

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  likedHotelsIds: state.likedHotels.itemsIds
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
