import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider } from '@material-ui/core/styles';
import initI18n from './helpers/language/i18n.config';
import store from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './views/App';

axios.defaults.baseURL = `${window._env_.ENDPOINT}`; // eslint-disable-line

initI18n(store);
moment.locale('ru');

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <CssBaseline />
      <App />
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
