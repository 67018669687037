import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@material-ui/core/Button';

import styles from './assets/Button.module.scss';

const ButtonCmp = ({ type, className, variant, children, ...buttonProps }) => (
  <Button
    className={cn(styles.button, className)}
    variant={variant}
    data-variant={variant}
    data-type={type}
    {...buttonProps}
  >
    {children}
  </Button>
);

ButtonCmp.defaultProps = {
  type: 'default',
  className: '',
  variant: 'contained'
};

ButtonCmp.propTypes = {
  type: PropTypes.oneOf(['default', 'apply']),
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.any.isRequired
};

export default ButtonCmp;
