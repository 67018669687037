import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cn from 'classnames';
import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';

import useUserProfile from 'common/compound-components/Header/hook/useUserProfile';
import FullInfoDialog from 'common/base-components/FullInfoDialog';
import Delighters from 'common/compound-components/Delighters/containers/DelightersContainer';
import Button from 'common/base-components/Button';

import { ReactComponent as RefreshIcon } from './assets/icons/refresh.svg';
import SelectedDelighters from './SelectedDelighters';
import NegativeComments from './NegativeComments';
import Recommendation from './Recommendation';

import styles from './assets/Dialog.module.scss';

const Stepper = ({ activeStep, children }) => React.Children.toArray(children)[activeStep];

const Step = ({ children }) => children;

/* eslint-disable indent */
const Dialog = ({
  hotelId,
  hotelName,
  delighters,
  initialUserFeedback,
  isModalOpen,
  onAdd,
  onClose
}) => {
  const { resetProfile } = useUserProfile();
  const { enqueueSnackbar } = useSnackbar();
  const [subDelighters, setSubDelighters] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedDelightersKeys, setSelectedDelightersKeys] = React.useState(
    initialUserFeedback?.positiveDelights?.map((el) => el.key) ?? null
  );
  const [positiveDelights, setPositiveDelights] = React.useState(
    initialUserFeedback?.positiveDelights ?? []
  );

  // From backend
  const [negativeCommentsList, setNegativeCommentsList] = React.useState([]);
  // Selected items
  const [negativeСomments, setNegativeСomments] = React.useState(
    initialUserFeedback?.negativeComments ?? []
  );
  const [recommendationState, setRecommendationState] = React.useState({
    recommendation: initialUserFeedback?.recommendation ?? '',
    leaveHotel: initialUserFeedback?.leaveHotel ?? null
  });

  React.useEffect(async () => {
    try {
      const { data } = await axios.get('/delights/for-feedback');

      setSubDelighters(data);
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  React.useEffect(async () => {
    try {
      const { data } = await axios.get('/hotels/user-feedback/negative-comments');

      setNegativeCommentsList(data);
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const handleResetActiveStep = React.useCallback(() => {
    if (activeStep === 0) {
      return setSelectedDelightersKeys(null);
    }

    if (activeStep === 1) {
      return setPositiveDelights((prevProps) =>
        prevProps.map((positiveDelight) => ({
          ...positiveDelight,
          subDelightKeys: [],
          comment: ''
        }))
      ); // eslint-disable-line
    }

    if (activeStep === 2) {
      return setNegativeСomments([]);
    }

    if (activeStep === 3) {
      return setRecommendationState({
        recommendation: '',
        leaveHotel: null
      });
    }

    return null;
  }, [activeStep]);

  // Convert and save positive delighters
  const handlePositiveDelightsChange = React.useCallback(({ key, subDelightKey, comment }) => {
    setPositiveDelights((prevPositiveDelights) => {
      const copiedPrevPositiveDelights = [...prevPositiveDelights];

      const positiveDelightIndex = copiedPrevPositiveDelights.findIndex((item) => item.key === key);

      if (subDelightKey) {
        copiedPrevPositiveDelights[
          positiveDelightIndex
        ].subDelightKeys = copiedPrevPositiveDelights[positiveDelightIndex].subDelightKeys.includes(
          subDelightKey
        )
          ? copiedPrevPositiveDelights[positiveDelightIndex].subDelightKeys.filter(
              (el) => el !== subDelightKey
            )
          : [...copiedPrevPositiveDelights[positiveDelightIndex].subDelightKeys, subDelightKey];
      }

      copiedPrevPositiveDelights[positiveDelightIndex].comment =
        comment ?? copiedPrevPositiveDelights[positiveDelightIndex].comment ?? '';

      return copiedPrevPositiveDelights;
    });
  }, []);

  const handleNegativeCommentsChange = React.useCallback((itemValue) => {
    setNegativeСomments((prevProps) => {
      if (prevProps.includes(itemValue)) {
        return prevProps.filter((el) => el !== itemValue);
      }

      return [...prevProps, itemValue];
    });
  }, []);

  const handleSubmit = React.useCallback(async () => {
    try {
      const newFeeback = {
        ...recommendationState,
        negativeСomments,
        positiveDelights
      };

      await axios.post(`/hotels/${hotelId}/user-feedback`, newFeeback);

      onAdd(newFeeback);
      onClose();
      setActiveStep(0);
    } catch (error) {
      if (error.response?.status === 401) {
        resetProfile();
        onClose();
        setActiveStep(0);

        enqueueSnackbar('Время сессии истекло. Войдите в систему для оставления отзыва.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });

        return;
      }

      enqueueSnackbar('Произошла ошибка', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  }, [hotelId, recommendationState, negativeСomments, positiveDelights]);

  const isFirstStepWithoutDelighters = React.useMemo(
    () => activeStep === 0 && !selectedDelightersKeys?.length,
    [activeStep, selectedDelightersKeys]
  );

  return (
    <FullInfoDialog
      open={isModalOpen}
      onClose={() => {
        // when 'Nothing liked' button was clicked from 1st step -> then we can't return to second (It hasn't selected delighters)
        if (activeStep - 1 === 1 && !selectedDelightersKeys?.length) {
          setActiveStep(0);

          return;
        }

        if (activeStep > 0) {
          setActiveStep(activeStep - 1);

          return;
        }

        onClose();
      }}
      renderRightSection={() => (
        <IconButton className={styles.refreshButton} onClick={handleResetActiveStep}>
          <RefreshIcon />
        </IconButton>
      )}
      btnText={null}
    >
      <div className={styles.wrapper}>
        <Stepper activeStep={activeStep}>
          <Step>
            <h4>Что мне понравилось?</h4>
            <h5>{hotelName}</h5>
            <Delighters
              withoutNumbers
              initialValues={selectedDelightersKeys ?? []}
              delighters={delighters}
              onChange={(delightersKeys) => {
                setSelectedDelightersKeys(delightersKeys);
              }}
            />

            <div className={styles.buttonWrapper}>
              <Button
                className={cn(styles.button, isFirstStepWithoutDelighters && styles.warningButton)}
                onClick={() => {
                  if (isFirstStepWithoutDelighters) {
                    setActiveStep(2); // NegativeComments

                    return;
                  }

                  setActiveStep(activeStep + 1);

                  if (positiveDelights.length === 0) {
                    // Generate positiveDelights if it's empty to pass to backend all items, not just changed
                    setPositiveDelights(
                      selectedDelightersKeys.map((delighterdKey) => ({
                        key: delighterdKey,
                        subDelightKeys: [],
                        comment: ''
                      }))
                    );
                  }
                }}
              >
                {isFirstStepWithoutDelighters ? 'Ничего не понравилось' : 'Далее'}
              </Button>
            </div>
          </Step>

          <Step>
            <SelectedDelighters
              selectedDelightersKeys={selectedDelightersKeys}
              positiveDelights={positiveDelights}
              onChange={handlePositiveDelightsChange}
              delighters={delighters}
              subDelighters={subDelighters}
              hotelName={hotelName}
            />
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                onClick={() => {
                  setActiveStep(activeStep + 1);
                }}
              >
                Далее
              </Button>
            </div>
          </Step>

          <Step>
            <NegativeComments
              items={negativeCommentsList}
              onChange={handleNegativeCommentsChange}
              negativeСomments={negativeСomments}
              hotelName={hotelName}
            />
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                onClick={() => {
                  setActiveStep(activeStep + 1);
                }}
              >
                Далее
              </Button>
            </div>
          </Step>

          <Step>
            <Recommendation
              hotelName={hotelName}
              recommendationState={recommendationState}
              setRecommendationState={setRecommendationState}
            />
            <div className={styles.buttonWrapper}>
              <Button className={styles.button} onClick={handleSubmit}>
                Готово
              </Button>
            </div>
          </Step>
        </Stepper>
      </div>
    </FullInfoDialog>
  );
};

Dialog.propTypes = {
  hotelId: PropTypes.number.isRequired,
  hotelName: PropTypes.string.isRequired,
  initialUserFeedback: PropTypes.object.isRequired,
  delighters: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Dialog;
