import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import getImageSrc from 'helpers/getImageSrc';
import LikeButton from 'common/compound-components/LikeButton/containers/LikeButtonContainers';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';

import styles from './assets/ImageSlider.module.scss';

const CustomDots = () => (
  <ul className="slick-dots">
    <li>
      <button type="button" aria-label="dot" />
    </li>
    <li>
      <button type="button" aria-label="dot" />
    </li>
    <li>
      <button type="button" aria-label="dot" />
    </li>
  </ul>
);

const ImageSlider = ({
  images,
  settings,
  className,
  wrapperClassName,
  imageBgClassName,
  hotelId
}) => {
  const sliderRef = React.useRef(null);
  const sliderSettings = React.useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'anticipated',
      ...settings
    }),
    []
  );

  React.useEffect(() => {
    const setBackgroundImage = (e, slick, image, imageSource) => {
      const div = document.createElement('div');
      const imageSrc = imageSource ?? '/noImageFound.png';
      div.style.backgroundImage = 'url("' + imageSrc + '")'; // eslint-disable-line
      div.className = cn(styles.imageBg, imageBgClassName);

      $(div).insertBefore(image);

      image.remove();
    };

    $(sliderRef.current)
      .slick(sliderSettings)
      .on('lazyLoaded', (e, slick, image, imageSource) => {
        setBackgroundImage(e, slick, image, imageSource);
      })
      .on('lazyLoadError', (e, slick, image) => {
        setBackgroundImage(e, slick, image);
      });

    return () => {
      $(sliderRef.current).slick('unslick');
    };
  }, [sliderRef.current]);

  const renderImages = React.useCallback((imageName = '') => {
    const imageUrl = imageName ? getImageSrc(imageName) : '/noImageFound.png';

    return (
      <div key={imageName} className={styles.slideItem}>
        <div className={styles.gradient} />
        <img className={cn(styles.imageBg, imageBgClassName)} data-lazy={imageUrl} alt="" />
      </div>
    );
  }, []);

  return (
    <div className={cn(styles.wrapper, wrapperClassName)}>
      <div ref={sliderRef} className={className}>
        {images.length === 0 && renderImages()}
        {images.map((image) => renderImages(image.name))}
      </div>
      {hotelId && (
        <div className={styles.likeButtonWrapper}>
          <LikeButton hotelId={hotelId} />
        </div>
      )}
      {images.length > 1 && <CustomDots />}
    </div>
  );
};

ImageSlider.defaultProps = {
  settings: {},
  className: undefined,
  wrapperClassName: undefined,
  imageBgClassName: undefined,
  hotelId: undefined
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  settings: PropTypes.object,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  imageBgClassName: PropTypes.string,
  hotelId: PropTypes.number
};

export default ImageSlider;
