import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducerRegistry from './reducerRegistry';

const initialState = {};

// Preserve initial state for not-yet-loaded reducers
const combine = (reducers) => {
  if (Object.keys(reducers).length === 0) {
    return () => {};
  }

  const reducerNames = Object.keys(reducers);
  const reducersInit = reducers;

  Object.keys(initialState).forEach((item) => {
    if (reducerNames.indexOf(item) === -1) {
      reducersInit[item] = (state = null) => state;
    }
  });

  return combineReducers(reducers);
};

const reducer = combine(reducerRegistry.getReducers());

const store = createStore(
  reducer,
  initialState,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunkMiddleware))
    : applyMiddleware(thunkMiddleware)
);

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener((reducers) => {
  store.replaceReducer(combine(reducers));
});

export default store;
