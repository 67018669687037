import React from 'react';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import useDidMountEffect from 'helpers/useDidMountEffectHook';
import SignInButton from 'common/compound-components/SignInButton';
import MenuDialog from './MenuDialogComponent';
import UsetProfile from './UsetProfileComponent';
import useUserProfile from '../hook/useUserProfile';

import styles from '../assets/Header.module.scss';

const HeaderComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isMobileDevice = useSelector((state) => state.settings.isMobileDevice);
  const history = useHistory();
  const location = useLocation();
  const { profile, resetProfile } = useUserProfile();

  useDidMountEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [location.pathname]);

  const handleLogoClick = React.useCallback(() => {
    history.push('/');
  }, [history]);

  const handleLogout = React.useCallback(() => {
    setIsMenuOpen(false);
    resetProfile();
  }, []);

  return (
    <div className={cn(styles.wrapper, !isMobileDevice && styles.wrapperWithWarning)}>
      <div className={styles.underlay} />
      <header className={cn(styles.header)}>
        <Container>
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid item xs={3}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleLogoClick}
                disabled={history.location.pathname === '/'}
                className={styles.logoBtn}
              >
                <img src="/logo.png" alt="AskBee" width="83" />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/+79166633652"
                className={styles.contacts}
              >
                <WhatsAppIcon />
                +79166633652
              </a>
            </Grid>
            <Grid item xs={5}>
              <div className={styles.actions}>
                {Object.keys(profile).length === 0 ? (
                  <SignInButton />
                ) : (
                  <UsetProfile
                    size="small"
                    profile={profile}
                    classes={{ userAvatar: styles.userAvatar }}
                  />
                )}
                <IconButton onClick={() => setIsMenuOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </header>
      <MenuDialog
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        profile={profile}
        onLogout={handleLogout}
      />
    </div>
  );
};

export default HeaderComponent;
