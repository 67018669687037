/* eslint-disable */
import React from 'react';

const FilterIcon = () => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="MuiSvgIcon-root"
  >
    <path d="M15.25 0C15.4375 0 15.625 0.09375 15.7812 0.21875C15.9062 0.375 16 0.5625 16 0.75C16 0.96875 15.9062 1.15625 15.7812 1.28125L10 7.0625V15.25C10 15.5625 9.84375 15.8125 9.59375 15.9375C9.3125 16.0625 9.0625 16.0625 8.8125 15.875L6.3125 14.125C6.09375 13.9688 6 13.75 6 13.5V7.0625L0.21875 1.28125C0.0625 1.15625 0 0.96875 0 0.75C0 0.5625 0.0625 0.375 0.21875 0.21875C0.34375 0.09375 0.53125 0 0.75 0H15.25Z" />
  </svg>
);

export default FilterIcon;
