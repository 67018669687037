import reducerRegistry from 'reducerRegistry';
import {
  DETAILS_GET_HOTEL_BEGIN,
  DETAILS_GET_HOTEL_SUCCESS,
  DETAILS_GET_HOTEL_ERROR,
  DETAILS_RESET_STATE
} from './actionTypes';

const initialState = {
  item: {},
  loading: false,
  error: ''
};

const detailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DETAILS_GET_HOTEL_BEGIN:
      return {
        ...state,
        loading: true
      };
    case DETAILS_GET_HOTEL_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case DETAILS_GET_HOTEL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DETAILS_RESET_STATE:
      return {
        item: {},
        loading: false,
        error: ''
      };
    default:
      return state;
  }
};

reducerRegistry.register('details', detailsReducer);
