import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import HotelCard from './HotelCard';

import styles from './assets/ScrollableHotelsList.module.scss';

const ScrollableHotelsList = ({ loading, items, size }) => {
  const renderHotelsList = () => {
    if (loading) {
      return (
        <div className={styles.loadingBlock}>
          <CircularProgress />
        </div>
      );
    }

    return items.map((item) => <HotelCard key={item.id} item={item} />);
  };

  return (
    <VerticalScrollableBlock className={styles.wrapper} data-size={size}>
      {renderHotelsList()}
    </VerticalScrollableBlock>
  );
};

ScrollableHotelsList.defaultProps = {
  size: 'default'
};

ScrollableHotelsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  loading: PropTypes.bool.isRequired
};

export default ScrollableHotelsList;
