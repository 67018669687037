import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import screenImg from './assets/Screen.png';

import styles from './assets/DesktopWarning.module.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide {...props} direction="up" ref={ref} timeout={{ ...props.timeout, enter: 0 }} />
));
Transition.displayName = 'Transition';

Transition.propTypes = {
  timeout: PropTypes.object.isRequired
};

const DesktopWarning = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(true);

  const handleAgreeBtnClick = React.useCallback(() => {
    setIsDialogOpen(false);
    localStorage.setItem('agreedDesktopView', true);
  }, []);

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      TransitionComponent={Transition}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <h3 className={styles.title}>Поиск отелей</h3>
          <p className={styles.description}>
            Сайт отображается корректно только на мобильных устройствах.
          </p>
          <Button variant="contained" onClick={handleAgreeBtnClick} className={styles.agreeButton}>
            Понятно!
          </Button>
        </div>
      </div>
      <div className={styles.screen}>
        <img src={screenImg} alt="screen" />
      </div>
    </Dialog>
  );
};

export default DesktopWarning;
