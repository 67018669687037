import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Title from 'common/base-components/Title';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import FeedbacksList from '../containers/FeedbacksListContainer';
import AllFeedbacksDialog from './AllFeedbacksDialogComponent';

import styles from '../assets/Feedbacks.module.scss';

const FeedbacksComponent = ({ hotelId }) => {
  const [openAllFeedbacksDialog, setOpenAllFeedbacksDialog] = React.useState(false);
  const [feedbacksTotalCount, setFeedbacksTotalCount] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});

  const handleClickOpen = React.useCallback((event) => {
    setOpenAllFeedbacksDialog(true);

    event.preventDefault();
  }, []);

  const handleClose = React.useCallback(() => {
    setOpenAllFeedbacksDialog(false);
  }, []);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setOpenAllFeedbacksDialog(true);
  };

  return (
    <div>
      <SpaceBetweenBlock>
        <Title>{feedbacksTotalCount} Впечатлений</Title>
        <IconButton onClick={handleClickOpen} className={styles.allFeedbacksBtn}>
          Все
          <ArrowForwardIosIcon />
        </IconButton>
      </SpaceBetweenBlock>
      <VerticalScrollableBlock>
        <FeedbacksList
          hotelId={hotelId}
          cardClassName={styles.card}
          onTotalCountChange={setFeedbacksTotalCount}
          onCardClick={handleCardClick}
          shouldAddTitleCaption
        />
      </VerticalScrollableBlock>
      <AllFeedbacksDialog
        open={openAllFeedbacksDialog}
        onClose={handleClose}
        hotelId={hotelId}
        selectedItem={selectedItem}
      />
    </div>
  );
};

FeedbacksComponent.propTypes = {
  hotelId: PropTypes.number.isRequired
};

export default FeedbacksComponent;
