import reducerRegistry from 'reducerRegistry';
import {
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_BEGIN,
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_SUCCESS,
  RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_ERROR
} from './actionTypes';

const initialState = {
  items: [],
  loading: false,
  error: ''
};

const recentlyViewedHotelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false
      };
    case RECENTLY_VIEWED_HOTELS_GET_RECENTLY_VIEWED_HOTELS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

reducerRegistry.register('recentlyViewedHotels', recentlyViewedHotelsReducer);
