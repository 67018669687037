import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import FullInfoDialog from 'common/base-components/FullInfoDialog';
import FeedbacksList from '../containers/FeedbacksListContainer';

import styles from '../assets/AllFeedbacksDialog.module.scss';

function TabPanel({ children, value, index, ...other }) {
  const [touchedTabsValues, setTouchedTabsValues] = React.useState({});

  React.useEffect(() => {
    if (value in touchedTabsValues) {
      return;
    }

    setTouchedTabsValues((prev) => ({ ...prev, [value]: true }));
  }, [value]);

  return (
    <div
      role="tabpanel"
      className={styles.tabPanelWrapper}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {index in touchedTabsValues && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function AllFeedbacksDialogContent({ hotelId, selectedItem }) {
  const emotionsList = React.useMemo(() => ['all', 'positive', 'neutral', 'negative'], []);
  const initialValue = React.useMemo(() => {
    const emotionIndex = emotionsList.findIndex((el) => el === selectedItem.emotion?.toLowerCase());

    return emotionIndex === -1 ? 0 : emotionIndex;
  }, []);
  const [value, setValue] = React.useState(initialValue);
  const [feedbacksTotalCount, setFeedbacksTotalCount] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTotalCountChange = (count, index) => {
    setFeedbacksTotalCount((prev) => ({ ...prev, [index]: count }));
  };

  const renderFeedbacksListByEmotion = (emotion = '', index, shouldAddTitleCaption) => (
    <FeedbacksList
      isFull
      hotelId={hotelId}
      emotion={emotion.toUpperCase()}
      shouldAddTitleCaption={shouldAddTitleCaption}
      selectedItem={selectedItem}
      cardClassName={styles.card}
      onTotalCountChange={(count) => handleTotalCountChange(count, index)}
    />
  );

  return (
    <Container>
      <Typography variant="h5" className={styles.title}>
        {feedbacksTotalCount[value] ?? 0} Впечатлений
      </Typography>
      <Tabs
        textColor="primary"
        className={styles.tabs}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Все" data-emotion={emotionsList[0]} />
        <Tab label="Положительные" data-emotion={emotionsList[1]} />
        <Tab label="Рекомендации" data-emotion={emotionsList[2]} />
        <Tab label="Негативные" data-emotion={emotionsList[3]} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {renderFeedbacksListByEmotion('', 0, true)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {renderFeedbacksListByEmotion(emotionsList[1], 1)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {renderFeedbacksListByEmotion(emotionsList[2], 2)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {renderFeedbacksListByEmotion(emotionsList[3], 3)}
      </TabPanel>
    </Container>
  );
}

AllFeedbacksDialogContent.propTypes = {
  hotelId: PropTypes.number.isRequired,
  selectedItem: PropTypes.object.isRequired
};

const AllFeedbacksDialogComponent = ({ open, onClose, ...props }) => (
  <FullInfoDialog open={open} onClose={onClose} title="Впечатления">
    <AllFeedbacksDialogContent {...props} />
  </FullInfoDialog>
);

AllFeedbacksDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hotelId: PropTypes.number.isRequired,
  selectedItem: PropTypes.object.isRequired
};

export default AllFeedbacksDialogComponent;
