import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './assets/StepCard.module.scss';

const StepCard = ({ title, description, coverSrc, centerCover }) => (
  <div className={styles.root}>
    <div className={cn(styles.cover, centerCover && styles.centerCover)}>
      <img src={coverSrc} alt={title} />
    </div>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.description}>{description}</div>
  </div>
);

StepCard.defaultProps = {
  centerCover: false
};

StepCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  centerCover: PropTypes.bool
};

export default StepCard;
