import React from 'react';
import PropTypes from 'prop-types';
import Title from 'common/base-components/Title';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';
import VerticalScrollableBlock from 'common/base-components/VerticalScrollableBlock';
import WeatherCard from './WeatherCardComponent';

import styles from '../assets/WeatherWidget.module.scss';

const WeatherWidgetComponent = ({ sliderClassName, country, location, getWeathers }) => {
  const [weatherMonths, setWeatherMonths] = React.useState([]);

  React.useEffect(() => {
    if (!country || !location) {
      return;
    }

    const onGetWeathers = async () => {
      try {
        const data = await getWeathers(country, location);

        const months = data.months ?? [];

        setWeatherMonths(months);
      } catch (error) {
        throw new Error(error);
      }
    };

    onGetWeathers();
  }, []);

  if (!weatherMonths.length) {
    return null;
  }

  return (
    <div className={sliderClassName}>
      <SpaceBetweenBlock>
        <Title>Погода</Title>
      </SpaceBetweenBlock>
      <div className={styles.sliderWrapper}>
        <VerticalScrollableBlock className={styles.wrapper}>
          {weatherMonths.map((item) => (
            <div key={item.num} className={styles.sliderItem}>
              <WeatherCard item={item} />
            </div>
          ))}
        </VerticalScrollableBlock>
      </div>
    </div>
  );
};

WeatherWidgetComponent.defaultProps = {
  sliderClassName: ''
};

WeatherWidgetComponent.propTypes = {
  sliderClassName: PropTypes.string,
  country: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  getWeathers: PropTypes.func.isRequired
};

export default WeatherWidgetComponent;
