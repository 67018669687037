import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import cn from 'classnames';
import axios from 'axios';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CardActionArea from '@material-ui/core/CardActionArea';
import customWithTranslation from 'helpers/language/customWithTranslation';
import LikeButton from 'common/compound-components/LikeButton/containers/LikeButtonContainers';
import ImageSlider from 'common/base-components/ImageSlider';
import HotelCardDelighters from '../HotelCardDelighters';

import styles from './assets/HotelCard.module.scss';

const { CancelToken } = axios;

const HotelCard = ({ item, selectedDelightersKeys, className, tBack, location, isInline }) => {
  const [delighters, setDelighters] = React.useState([]);

  const source = React.useRef(CancelToken.source());

  const getDelighters = async () => {
    try {
      const { data } = await axios.get(`/hotels/${item.id}/delights`, {
        cancelToken: source.current.token
      });

      setDelighters(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  React.useEffect(() => {
    getDelighters();

    return () => {
      source.current.cancel();
    };
  }, []);

  return (
    <div className={cn(styles.wrapper, className)}>
      <Link
        to={{
          pathname: `/details/${item.id}`,
          search: location.search,
          hash: location.hash
        }}
        target="_blank"
        className={cn(isInline && styles.inlineLinkWrapper)}
      >
        <div className={styles.sliderWrapper}>
          <ImageSlider images={item.images} imageBgClassName={styles.imageBg} />
          <div className={styles.likeButtonWrapper}>
            <LikeButton hotelId={item.id} />
          </div>
        </div>
        <CardActionArea className={styles.contentWrapper}>
          <section className={styles.content}>
            <div className={styles.contentInfo}>
              <h5 className={styles.title}>{item.name}</h5>
              <p className={styles.location}>
                <LocationOnOutlinedIcon className={styles.locationIcon} />
                {tBack(item, 'locationName')}
              </p>
            </div>
            <div className={styles.priceBlock}>
              {item.priceTo} <span className={styles.currency}>EUR</span>
            </div>
          </section>
          <HotelCardDelighters
            delighters={delighters}
            selectedDelightersKeys={selectedDelightersKeys}
            className={styles.delightersWrapper}
          />
        </CardActionArea>
      </Link>
    </div>
  );
};

HotelCard.defaultProps = {
  className: undefined,
  isInline: false,
  selectedDelightersKeys: undefined
};

HotelCard.propTypes = {
  item: PropTypes.object.isRequired,
  selectedDelightersKeys: PropTypes.arrayOf(PropTypes.string),
  tBack: PropTypes.func.isRequired,
  className: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired
  }).isRequired,
  isInline: PropTypes.bool
};

export default withRouter(customWithTranslation()(HotelCard));
