import { connect } from 'react-redux';
import axios from 'axios';
import DetailsComponent from '../components/DetailsComponent';
import { getHotelBegin, getHotelSuccess, getHotelError, resetState } from '../actions';
import '../index';

const getHotel = (hotelId) => async (dispatch) => {
  try {
    dispatch(getHotelBegin());

    const { data } = await axios.get(`/hotels/${hotelId}`);

    dispatch(getHotelSuccess(data));
  } catch (error) {
    dispatch(getHotelError(error));

    throw new Error(error);
  }
};

const setAsViewedHotel = (hotelId) => {
  const viewedHotelsIdsStorage = JSON.parse(localStorage.getItem('viewedHotelsIds')) ?? [];

  const newViewedHotelsIdsStorage = viewedHotelsIdsStorage
    .filter((el) => el !== hotelId)
    .concat(hotelId);

  localStorage.setItem('viewedHotelsIds', JSON.stringify(newViewedHotelsIdsStorage));
};

const mapDispatchToProps = (dispatch) => ({
  getHotel: (hotelId) => dispatch(getHotel(hotelId)),
  setAsViewedHotel: (hotelId) => setAsViewedHotel(hotelId),
  resetState: () => dispatch(resetState())
});

const mapStateToProps = (state) => ({
  loading: state.details.loading,
  item: state.details.item
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
