import React from 'react';
import PropTypes from 'prop-types';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SpaceBetweenBlock from 'common/base-components/SpaceBetweenBlock';
import customWithTranslation from 'helpers/language/customWithTranslation';

import styles from '../assets/HeaderDetails.module.scss';

const HeaderComponent = ({ tBack, item }) => (
  <header>
    <SpaceBetweenBlock className={styles.spaceBetweenBlock}>
      <div>
        <h5 className={styles.title}>{item.name}</h5>
        <p className={styles.location}>
          <LocationOnOutlinedIcon className={styles.locationIcon} />
          {tBack(item, 'locationName')}
        </p>
      </div>
      <p className={styles.priceBlock}>{item.priceTo} EUR</p>
    </SpaceBetweenBlock>
  </header>
);

HeaderComponent.propTypes = {
  tBack: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default customWithTranslation()(HeaderComponent);
