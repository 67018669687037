import React from 'react';
import PropTypes from 'prop-types';
import ScrollableHotelsSection from 'common/base-components/ScrollableHotelsSection';
import usePrevious from 'helpers/usePreviousHook';

const LikedHotelsList = ({ itemsIds, items, getLikedHotels, loadMoreHotel }) => {
  const prevItemsIds = usePrevious(itemsIds);

  React.useEffect(() => {
    getLikedHotels(itemsIds);
  }, []);

  React.useEffect(() => {
    if (itemsIds.length > prevItemsIds.length) {
      loadMoreHotel(items, itemsIds);
    }
  }, [itemsIds]);

  return (
    <div>
      <ScrollableHotelsSection title="Мне нравится" items={items} size="small" />
    </div>
  );
};

LikedHotelsList.propTypes = {
  itemsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLikedHotels: PropTypes.func.isRequired,
  loadMoreHotel: PropTypes.func.isRequired
};

const LikedHotelsComponent = (props) => {
  const { itemsIds, items } = props;

  if (items.length === 0 && itemsIds.length === 0) {
    return null;
  }

  return <LikedHotelsList {...props} />;
};

LikedHotelsComponent.propTypes = {
  itemsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLikedHotels: PropTypes.func.isRequired,
  loadMoreHotel: PropTypes.func.isRequired
};

export default LikedHotelsComponent;
