import React from 'react';
import PropTypes from 'prop-types';
import ScrollableHotelsSection from 'common/base-components/ScrollableHotelsSection';

const RecentlyViewedHotelsComponent = ({ getRecentlyViewedHotels, items, loading }) => {
  const recentlyViewedHotelsIds = React.useMemo(
    () => JSON.parse(localStorage.getItem('viewedHotelsIds')),
    []
  );

  React.useEffect(() => {
    if (recentlyViewedHotelsIds) {
      getRecentlyViewedHotels(recentlyViewedHotelsIds);
    }
  }, []);

  if (!recentlyViewedHotelsIds) {
    return null;
  }

  return (
    <ScrollableHotelsSection
      title="Недавно просмотренные"
      items={items}
      size="small"
      loading={loading}
    />
  );
};

RecentlyViewedHotelsComponent.propTypes = {
  getRecentlyViewedHotels: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired
};

export default RecentlyViewedHotelsComponent;
