import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/base-components/Button';
import FullInfoDialog from 'common/base-components/FullInfoDialog';

import ActivateCodeDialog from 'common/compound-components/ActivateCodeDialog';
import UsetProfile from './UsetProfileComponent';
import MenuList from './MenuListComponent';

import styles from '../assets/MenuDialog.module.scss';

const MenuComponent = ({ isOpen, onClose, profile, onLogout }) => {
  const [isActivateCodeDialogOpen, setIsActivateCodeDialogOpen] = React.useState(false);
  const isLoggedIn = React.useMemo(() => profile && Object.keys(profile).length > 0, [profile]);

  return (
    <FullInfoDialog fullScreen open={isOpen} onClose={onClose} btnText={null}>
      <div className={styles.root}>
        {isLoggedIn && (
          <div className="userProfileWrapper">
            <UsetProfile profile={profile} />
          </div>
        )}
        <MenuList
          isLoggedIn={isLoggedIn}
          profile={profile}
          onActivateCodeClick={() => {
            setIsActivateCodeDialogOpen(true);
          }}
        />
        {isLoggedIn && (
          <Button variant="text" className={styles.logoutBtn} onClick={onLogout}>
            Выйти
          </Button>
        )}
      </div>
      <ActivateCodeDialog
        open={isActivateCodeDialogOpen}
        onClose={() => setIsActivateCodeDialogOpen(false)}
      />
    </FullInfoDialog>
  );
};

MenuComponent.defaultProps = {
  profile: undefined
};

MenuComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    active: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
    invitedCode: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string
  }),
  onLogout: PropTypes.func.isRequired
};

export default MenuComponent;
