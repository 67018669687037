import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import SearchResultsComponent from '../components/SearchResultsComponent';
import { getHotelsBegin, getHotelsSuccess, getHotelsError, resetState } from '../actions';
import '../index';

const getHotels = (searchParams, isLoadMore) => async (dispatch, getState) => {
  try {
    dispatch(getHotelsBegin());

    const {
      searchResults: { items }
    } = getState();
    const { delighters, likedHotelsIds, facts, locationName } = searchParams;

    const { data } = await axios.get('/hotels', {
      params: {
        locationName,
        delight: delighters,
        like: likedHotelsIds,
        fact: facts,
        displayed: isLoadMore && items.map((item) => item.id)
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
    const hasMore = data.length > 0;
    const checkedData = isLoadMore ? [...items, ...data] : data;

    dispatch(getHotelsSuccess({ items: checkedData, hasMore }));
  } catch (error) {
    dispatch(getHotelsError(error));

    throw new Error(error);
  }
};

const mapDispatchToProps = (dispatch) => ({
  getHotels: (searchParams, isLoadMore) => dispatch(getHotels(searchParams, isLoadMore)),
  resetState: () => dispatch(resetState())
});

const mapStateToProps = (state) => ({
  loading: state.searchResults.loading,
  items: state.searchResults.items,
  hasMore: state.searchResults.hasMore
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsComponent);
