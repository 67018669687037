import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';
import FeedbacksListComponent from '../components/FeedbacksListComponent';

const FEEDBACK_SIZE = 20;

const getFeedbacks = async (hotelId, emotion, page) => {
  try {
    const data = await axios.get(`/hotels/${hotelId}/feedbacks`, {
      params: {
        page,
        size: FEEDBACK_SIZE,
        emotion: emotion ? [emotion] : undefined
      },
      paramsSerializer: (params) => qs.stringify(params)
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const mapDispatchToProps = () => ({
  getFeedbacks
});

export default connect(null, mapDispatchToProps)(FeedbacksListComponent);
