import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as LowDegreeIcon } from '../assets/LowDegree.svg';
import { ReactComponent as CloudyIcon } from '../assets/weatherIcons/Cloudy.svg';
import { ReactComponent as FullSunIcon } from '../assets/weatherIcons/FullSun.svg';
import { ReactComponent as HalfSunIcon } from '../assets/weatherIcons/HalfSun.svg';
import { ReactComponent as HalfRainIcon } from '../assets/weatherIcons/HalfRain.svg';
import { ReactComponent as FullRainIcon } from '../assets/weatherIcons/FullRain.svg';

import styles from '../assets/WeatherCard.module.scss';

const WeatherCardComponent = ({ item }) => {
  /*
  Icon types:
  1-full sun
  2-half sun
  3-cloudy
  4-half rain
  5-full rain
*/
  const getIconByType = React.useCallback(() => {
    switch (item.iconType) {
      case 1:
        return <FullSunIcon className="MuiSvgIcon-root" />;
      case 2:
        return <HalfSunIcon className="MuiSvgIcon-root" />;
      case 3:
        return <CloudyIcon className="MuiSvgIcon-root" />;
      case 4:
        return <HalfRainIcon className="MuiSvgIcon-root" />;
      case 5:
        return <FullRainIcon className="MuiSvgIcon-root" />;
      default:
        return <FullSunIcon className="MuiSvgIcon-root" />;
    }
  }, [item.iconType]);

  return (
    <div className={styles.wrapper}>
      <div className="card">
        <div className={styles.month}>
          {moment()
            .month(item.num - 1)
            .format('MMM')}
        </div>
        <div className={styles.degree}>
          {getIconByType()}
          {item.airTemperature}°C
        </div>
        <div className={styles.degree}>
          <LowDegreeIcon className="MuiSvgIcon-root" />
          {item.waterTemperature}°C
        </div>
      </div>
    </div>
  );
};

WeatherCardComponent.propTypes = {
  item: PropTypes.shape({
    num: PropTypes.number,
    waterTemperature: PropTypes.number,
    airTemperature: PropTypes.number,
    iconType: PropTypes.number
  }).isRequired
};

export default WeatherCardComponent;
