import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'common/base-components/Button';

import styles from './assets/FixedFiltersButton.module.scss';

const FixedFiltersButton = ({ className, children, ...buttonProps }) => (
  <Button className={cn(className, styles.button)} {...buttonProps}>
    {children}
  </Button>
);

FixedFiltersButton.defaultProps = {
  className: undefined
};

FixedFiltersButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

export default FixedFiltersButton;
