import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './assets/NegativePoints.module.scss';

const NegativeComments = ({ onChange, negativeСomments, hotelName, items }) => (
  <div className={styles.wrapper}>
    <h4>Что мне не понравилось?</h4>
    <h5>{hotelName}</h5>
    <ul>
      {items.map((negativeComment) => (
        <li key={negativeComment} role="presentation" onClick={() => onChange(negativeComment)}>
          {negativeComment}{' '}
          <Checkbox
            color="primary"
            className={styles.checkbox}
            checked={negativeСomments.includes(negativeComment)}
          />
        </li>
      ))}
    </ul>
  </div>
);

NegativeComments.defaultProps = {
  items: []
};

NegativeComments.propTypes = {
  onChange: PropTypes.func.isRequired,
  hotelName: PropTypes.string.isRequired,
  negativeСomments: PropTypes.array.isRequired,
  items: PropTypes.array
};

export default NegativeComments;
